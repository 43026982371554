import React, {useState} from 'react';
import {formatCodeInput, validateOnlyNumbers} from '../../util/Util';
import PSButton from '../components/PSButton';
import PSTitle from '../components/PSTitle';
import PSLoginContainer from '../components/PSLoginContainer';
import PSBox from '../components/PSBox';
import PSIcon from '../components/PSIcon';
import PSSelect from '../components/PSSelect';
import PSLink from '../components/PSLink';
import PSTextInput from '../components/PSTextInput';
import PSRadio from '../components/PSRadio';
import PSCheckBox from '../components/PSCheckBox';
import PSSubtitle from '../components/PSSubtitle';
import PSAsideBar from '../components/PSAsideBar';
import PSMessageResponse from '../components/PSMessageResponse';
import PSPrivacyPolicies from "../components/PSPrivacyPolicies";
import PSComponentFrom from "../components/PSComponentForm";
import PSPreviewFile from "../components/PSPreviewFile";
import PSMap from "../components/PSMap";
import PSPreviewFileHtml from "../components/PSPreviewFileHTML";
import PSFileConstanciaAsegurado from "../components/PSFileConstanciaAsegurado";
import {
  PSFileBoleta2,
  PSFileBoleta,
  PSConstanciaAseguradoSeguro,
  PSConstanciaAseguradoSCTR, PSModalErrorResponse, PSSatisfactionSurvey, PSModalSatisfactionSurvey
} from "../components/organisms";
import PSConstanciaPersonalAsegurado
  from "../components/organisms/PSConstanciaPersonalAsegurado/PSConstanciaPersonalAsegurado";
import {Routes} from "../../routes";
import { DescriptionForm } from '../shared/constants/Strings';
const from = [
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value:''},
  { field: 'afiliado', label: 'Afiliado:', type: 'input',value:'' },
  { field: 'cobrante:', label: 'Cobrante:', type: 'input' ,value:''},
  { field: 'pago', label: 'Forma de pago actual:', type: 'select', value:'' },
];
export default function Home() {

  const [valueSelected, setValueSelected] = useState(7);
  const [showModal,setShowModal] = useState(true);
  return (
    <div>
      <PSLoginContainer>
        <PSComponentFrom from = {from}/>
        <PSBox>
          <PSButton>dadadasd</PSButton>
          <PSTitle>tttttttt</PSTitle>
          <PSSubtitle>texttt</PSSubtitle>
        </PSBox>
        <PSIcon type="home"/>
        <PSIcon type="face"/>

        <PSSelect
          options={[
            {value: 'v1', label: 'value1'},
            {value: 'v2', label: 'value2'},
            {value: 'v3', label: 'value3'},
          ]}
          selectedValue="value1"
        />
        <PSLink href="https://www.linkedin.com/feed/">link to gooo</PSLink>
        <PSTextInput/>
        <PSTextInput type="password"/>
        <PSCheckBox>selection</PSCheckBox>
        <PSRadio>selection</PSRadio>
      </PSLoginContainer>
      <PSMessageResponse title="Contraseña válida" icon="face"/>
      <div style={{width : "700px"}}>
        <PSPreviewFileHtml
          typeClassPrint="sctr"
          typeDocument={DescriptionForm.CDA}
        >
          <PSConstanciaPersonalAsegurado
            id="canvas-wrapper-content"
          />
        </PSPreviewFileHtml>
      </div>
        <PSModalErrorResponse />

      {/*<PSModalSatisfactionSurvey*/}
      {/*  onSelectValue={(value)=>setValueSelected(value)}*/}
      {/*  valueSelected={valueSelected}*/}
      {/*  openModal={showModal}*/}
      {/*  onClose={()=>setShowModal(false)}*/}
      {/*/>*/}
    </div>
  );
}
