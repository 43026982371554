import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { services } from '../../../util/Constant';
import { Coverage } from '../../shared/constants/Coverage';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { getProperty, isEmpty, reorderMonths } from '../../../util/Util';
import { monthsRenta, monthsRenta2023, monthsRRVV, monthsRRVV2023 } from './constant';

import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSLabel from '../../components/PSLabel';
import PSCalendar from '../../components/PSCalendar';
import PSGrayBoxContainer from '../../components/PSGrayBoxContainer';
import PSQuestion from '../../components/PSQuestion';
import PSCoverage from '../../components/PSCoverage';
import PSCarousel from '../../components/PSCarousel';
import { GAProvider } from '../../components/PSAnalytics';

import './index.scss';
import 'react-calendar/dist/Calendar.css';

const img = 'header-schedule';
const imgMobile = 'header-schedule-mobile';

export default function HomeSchedule(props) {
  const currentDate = new Date();
  const [value, onChange] = useState(currentDate);
  const [currentOption, setCurrentOption] = useState({});
  const infoPage = getProperty(props.location.pathname.split('/')[3]);
  const ga = React.useContext(GAProvider);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const isRRVV = props.match.path.includes('rrvv');
  const currentAnio = currentDate.getFullYear();
  const months2022 = isRRVV ? monthsRRVV : monthsRenta;
  const months2023 = isRRVV ? monthsRRVV2023 : monthsRenta2023;
  const monthsFinal = currentAnio === 2022 ? months2022 : months2023;
  const monthsMobile = reorderMonths(monthsFinal);

  useEffect(() => {
    const type = props.location.pathname.split('/')[3];
    let typeProduct = '';
    switch (type) {
      case 'rrvv':
        typeProduct = 'Rentas Vitalicias';
        break;
      case 'rentaTotal':
        typeProduct = 'Renta Total Protecta';
        break;
      case 'ahorroTotal':
        typeProduct = 'Ahorro Total Protecta';
        break;
    }
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto ${typeProduct}-Cronograma de Pagos`,
      action: 'click',
      label: `El usuario visitó la página de Cronograma de Pagos`,
      value: 1,
    });
  }, []);

  return (
    <div className="schedule-format-container">
      <div className="header">
        <PSHeaderIntranet
          img={img}
          imgMobile={imgMobile}
          description="Cronograma de Pagos"
          icon="downloadFormat"
          {...props}
        />
      </div>
      <div className="body">
        <div className="section-title mt-2">
          <PSLabel className="title show-web">Calendario anual</PSLabel>
          <PSLabel className="title show-mobile">Calendario {currentAnio}</PSLabel>
          <PSLabel className="year show-web">{currentAnio}</PSLabel>
        </div>
        <div className="section-calendar">
          <div className="calendar-annual columns is-gapless is-multiline is-mobile display-none-response">
            {monthsFinal.map((m) => (
              <PSCalendar
                className="calendar column is-3-desktop is-6-tablet is-12-mobile"
                key={m.id}
                onChange={onChange}
                value={m.value}
                activeStartDate={m.startDate}
                showNeighboringMonth={false}
                number={0}
              />
            ))}
          </div>
          <div className="calendar-annual columns is-gapless is-multiline is-mobile display-only-response">
            <PSCarousel typeIcon="secondary">
              {monthsMobile.map((m) => (
                <PSCalendar
                  className="calendar column"
                  key={m.id}
                  onChange={onChange}
                  value={m.value}
                  activeStartDate={m.startDate}
                  showNeighboringMonth={false}
                  number={1}
                  isMobile
                />
              ))}
            </PSCarousel>
          </div>
        </div>
        <div className="note-footer">
          <h6 className="align-justify">
            <b>Nota</b>: Este cronograma aplica solo para las pensiones pagadas directamente por
            Protecta Security (Modalidad: Jubilación o Sobrevivencia por Jubilación), si recibes tu
            pensión a través de tu AFP (Modalidad: Invalidez o Sobrevivencia Directa), el cronograma
            lo debes consultar en la página web de tu AFP.
          </h6>
        </div>
        <hr />
        <div className="section-most-consulted">
          {isEmpty(currentOption) ? (
            <>
              <div className="section-title mt-1">
                <PSLabel className="title">{`Más consultados ${infoPage.title}`}</PSLabel>
              </div>
              <div className="columns mt-1 display-none-response">
                {services.map((service, index) => (
                  <PSGrayBoxContainer
                    key={service.description}
                    description={service.description}
                    icon={service.icon}
                    className="column is-one-third box-container-home"
                    onClick={() => setCurrentOption({ ...services[index] })}
                  />
                ))}
              </div>

              <PSCarousel typeIcon="secondary" className="display-only-response">
                {services.map((service, index) => (
                  <PSGrayBoxContainer
                    key={service.description}
                    description={service.description}
                    icon={service.icon}
                    className="column is-one-third box-container-home"
                    onClick={() => setCurrentOption({ ...services[index] })}
                  />
                ))}
              </PSCarousel>
            </>
          ) : (
            <div>
              <div className="container-title-section">
                <PSLabel className="title">{currentOption.description}</PSLabel>
                <div
                  onClick={() => {
                    setCurrentOption({});
                  }}
                >
                  <PSLabel className="cursor-pointer">{'<< Atrás'} </PSLabel>
                </div>
              </div>

              {currentOption.description === 'Preguntas frecuentes' && (
                <PSQuestion questions={FrequentQuestions[infoPage.property]} />
              )}

              {currentOption.description === 'Resumen de coberturas' && (
                <PSCoverage ListCoverage={Coverage[infoPage.property]} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
