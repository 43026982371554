import {STORAGE_IS_BUSINESS} from "../../../util/Constant";

const isBussines = localStorage.getItem(STORAGE_IS_BUSINESS) ==='true';

export const FrequentQuestions = {
  RENTA_TOTAL: !isBussines ? [
    {
      id: '0',
      question: '¿Qué es Renta Total Protecta?',
      description:
        'Es una renta particular o también conocida como temporal que permite tener una mensualidad por un tiempo determinado, una tasa fija garantizada y la devolución del capital invertido en su totalidad. Además, garantiza el pago a los beneficiarios en caso de fallecimiento.',
    },
    {
      id: '1',
      question: '¿Podría tomar más de una póliza del Seguro Renta Total Protecta?',
      description: 'Sí, las pólizas que el cliente desee.',
    },
    {
      id: '2',
      question: '¿Quiénes pueden contratar una Renta Total Protecta Security?',
      description:
        'Todas las personas mayores de edad que cuenten con un capital excedente para invertir, desde 30 mil soles o 10 mil dólares.',
    },
    {
      id: '3',
      question: '¿Por cuánto tiempo podríamos tomar una Renta Total Protecta?',
      description: 'La podría tomar por periodos de 5, 7, 10, 15 y 20 años.',
    },
    {
      id: '4',
      question: '¿Esta póliza la puede pagar mi empleador o algún familiar para mí?',
      description:
        'Esta póliza sí puede tener contratante distinto al asegurado, sin embargo no puede ser una persona jurídica. Ejemplo: no califica mi empleador pero sí un familiar.',
    },
    {
      id: '5',
      question: '¿Puedo anular mi Renta Total Protecta?',
      description:
        'No es posible, esta póliza es irrevocable. \n' +
        'Si bien la póliza es irrevocable el cliente puede poner fin a la póliza de manera adelantada y cobrar el valor de rescate de acuerdo a lo establecido en los documentos de la póliza, para ello deberá haber transcurrido el plazo estipulado.',
    },
    {
      id: '6',
      question:
        '¿Los beneficiarios tienen que ser necesariamente mi esposa y mis hijos? ¿O cualquier persona que yo elija?',
      description:
        ' El asegurado tiene la libertad de designar libremente a sus beneficiarios hasta completar el 100%. Inclusive puede ser una persona que no tenga consanguineidad con el asegurado.',
    },
    {
      id: '7',
      question: '¿Un cliente extranjero puede comprar este seguro?',
      description: 'Sí, siempre en cuando tenga residencia en el Perú y Carnet de Extranjería.',
    },
    {
      id: '8',
      question: '¿Un cliente peruano con residencia en otro país, puede comprar este seguro?',
      description:
        'Si lo puede comprar, siempre que en su DNI o documento de identidad mencione una residencia en el Perú.',
    },
    {
      id: '9',
      question: '¿Por qué tengo que dar información de donde saco el dinero para pagar la prima?',
      description:
        'Porque existe una ley en el Perú que por encima de $10,000 dólares o el equivalente en soles, se tiene que declarar el origen de los fondos para evitar el lavado de activos o financiamiento del terrorismo.',
    },
    {
      id: '10',
      question: '¿Es embargable esta póliza, si tengo deuda?',
      description:
        'Sí es embargable. Funciona como cualquier depósito en el sistema financiero. Distinto a una Renta Vitalicia que no puede ser embargada, a no ser por un juicio de pensión alimenticia.',
    },
    {
      id: '11',
      question: '¿Pago impuesto a la Renta o IGV?',
      description:
        'No, estos productos están exentos de impuestos por tratarse de un seguro de vida como lo determina la ley.',
    },
    {
      id: '12',
      question: '¿Qué exclusión tiene este Seguro?',
      description: 'Una sola, por acto criminal del beneficiario.',
    },
    {
      id: '13',
      question:
        'En la Cobertura de pago por Tramos ¿Puedo tomar los tramos eligiendo cualquier Renta Temporal?',
      description: 'Esta cobertura no aplica para Rentas Temporales de 5 y 7 años.',
    },
  ] : [],
  AHORRO_TOTAL: !isBussines ? [
    {
      id: '1',
      question: '¿Qué es Ahorro Total Protecta?',
      description:
        'Seguro de vida que  permite invertir un monto en soles o dólares haciendo crecer tu capital a una tasa fija por un período determinado y con la posibilidad de doble cobertura por Fallecimiento Accidental del Asegurado',
    },
    {
      id: '2',
      question: '¿Podría tomar más de una póliza del Seguro Ahorro Total Protecta?',
      description: 'Sí, las pólizas que el cliente desee.',
    },
    {
      id: '3',
      question: ' ¿Quiénes pueden contratar un Ahorro Total Protecta Security?',
      description:
        'Todas las personas mayores de edad que cuenten con un capital excedente para invertir, desde 20 mil soles o 5 mil dólares.',
    },
    {
      id: '4',
      question: '  ¿Esta póliza la puede pagar mi empleador o algún familiar para mí?',
      description:
        'Esta póliza sí puede tener contratante distinto al asegurado, sin embargo no puede ser una persona jurídica. Ejemplo: no califica mi empleador pero sí un familiar.',
    },
    {
      id: '5',
      question:
        '¿Los beneficiarios tienen que ser necesariamente mi esposa y mis hijos? ¿O cualquier persona que yo elija?',
      description:
        'El asegurado tiene la libertad de designar libremente a sus beneficiarios hasta completar el 100%. Inclusive puede ser una persona que no tenga consanguineidad con el asegurado.',
    },
    {
      id: '6',
      question: ' ¿Un cliente extranjero puede comprar este seguro?',
      description: 'Sí, siempre en cuando tenga residencia en el Perú y Carnet de Extranjería.',
    },
    {
      id: '7',
      question: '¿Un Cliente peruano con residencia en otro país, puede comprar este seguro?',
      description:
        'Si lo puede comprar, siempre en cuando su DNI o documento de identidad mencione una residencia en el Perú',
    },
    {
      id: '8',
      question: '¿Porqué tengo que dar información de donde saco el dinero para pagar la prima?',
      description:
        ' Porque existe una ley en el Perú que por encima de $10,000 dólares o el equivalente en soles, se tiene que declarar el origen de los fondos para evitar el lavado de activos o financiamiento por terrorismo.',
    },
    {
      id: '9',
      question: '¿Pago impuesto a la Renta o IGV?',
      description:
        'No, estos productos están exentos de impuestos por tratarse de un seguro de vida como lo determina la ley.',
    },
  ] :[],
  SCTR: !isBussines ? [
    {
      id: '1',
      question: '¿Qué es un Seguro de SCTR?',
      description:
        'Seguro para empresas que protege al trabajador, de la empresa contratante, frente a un siniestro derivado de accidentes laboral y/o enfermedades profesionales no cubiertas por el régimen de pensiones a cargo de la ONP y/o AFP y que estén contempladas en las condiciones de la póliza contratada.',
    },
    {
      id: '2',
      question: '¿Qué es un accidente laboral?',
      description:
        'Se considera accidente laboral o de trabajo toda lesión orgánica o perturbación funcional causada en el centro de trabajo o con ocasión del trabajo, por acción imprevista, fortuita u ocasional de una fuerza externa, repentina y violenta que obra súbitamente sobre la persona del trabajador o debido al esfuerzo del mismo (de acuerdo con el Decreto Supremo Nº 009 - 97 - SA, artículo 2, inciso K).',
    },
    {
      id: '3',
      question: '¿Qué necesito para adquirir un seguro SCTR?',
      description:
        'Residir en el Perú,\n' +
        'encontrarse en la planilla de una empresa que por su actividad este obligada a contratar el SCTR o que por motivo de sus labores se encontrarse destacado en una empresa con esta obligación.',
    },
    {
      id: '4',
      question: '¿Cuánto tiempo tengo para reclamar los Beneficios?',
      description:
        'La pensión de invalidez a favor del Asegurado se devengará desde el día siguiente de finalizado el período de 11 meses y 10 días consecutivos, correspondiente al subsidio por incapacidad temporal que otorga el Seguro Social de Salud (ESSALUD), siempre y cuando persista la condición de invalidez parcial o total, de naturaleza temporal o permanente.\n' +
        '\n' +
        'La pensión de Sobrevivencia, el plazo para la presentación de los beneficiarios es de 120 días calendario contados a partir de la fecha de fallecimiento o declaración judicial de muerte presunta del Asegurado.',
    },
    {
      id: '5',
      question: '¿Desde que fecha inicia el pago de la pensión de Sobrevivencia ?',
      description:
        'Las pensiones devengan desde la fecha del siniestro – fecha de fallecimiento del Asegurado.\n' +
        '\n' +
        'Los beneficiarios que se presenten con posterioridad al plazo de los 120 días calendarios, no perderán su derecho a las pensiones de sobrevivencia, pero éstas sólo se devengarán desde la fecha de presentación de su solicitud de pensión. \n' +
        '\n' +
        'De haberse presentado otros beneficiarios con anterioridad, la presentación de nuevos beneficiarios no generará aporte adicional a cargo de Protecta Security, sino un recálculo del capital requerido ya calculado.',
    },
    {
      id: '6',
      question:
        '¿Qué necesito si deseo solicitar la indeminización por invalidez total permanente?\n',
      description:
        'Un mes antes de concluir el período de subsidios por incapacidad temporal, en caso que la incapacidad persista, debes iniciar el trámite de Evaluación y Calificación de Invalidez ante ONP o COMAFP según correspoda.',
    },
  ]:[
    {
      id: '1',
      question: '¿Qué es un Seguro de SCTR?',
      description:
        'Seguro para empresas que protege al trabajador, de la empresa contratante, frente a un siniestro derivado de accidentes laboral y/o enfermedades profesionales no cubiertas por el régimen de pensiones a cargo de la ONP y/o AFP y que estén contempladas en las condiciones de la póliza contratada.',
    },
    {
      id: '2',
      question: '¿Cuáles son las coberturas que cubre un Seguro de SCTR en Protecta Security?',
      description:
        '•\tPensión o indemnización de invalidez total o parcial y temporal o permanente.\n' +
        '•\tPensión de sobrevivencia.\n' +
        '•\tReembolso de gastos de sepelio.',
    },
    {
      id: '3',
      question: '¿Este seguro tiene Exclusiones? ¿Cuáles son?',
      description:
      'Si, el seguro tiene exclusiones.\n' +
      '\n' +
      'Protecta Security queda eximida de cualquier obligación con respecto a un accidente de trabajo o a una enfermedad profesional en los siguientes casos:\n' +
      '\n' +
      '•\tInvalidez configurada antes del inicio de vigencia de la póliza de seguro, la misma que deberá ser amparada por la Aseguradora que otorgó la cobertura al tiempo de la configuración de la invalidez o, por la Oficina de Normalización Previsional (ONP), en el caso que el Trabajador, con calidad de asegurado obligatorio no hubiera estado asegurado.\n' +
      '•\tMuerte o invalidez causada por lesiones voluntariamente auto infligidas o auto eliminación o su tentativa.\n' +
      '•\tLa muerte o invalidez de los trabajadores asegurables que no hubieran sido declarados por el Contratante, cuyas pensiones serán a cargo de la Oficina de Normalización Previsional (ONP).\n' +
      '•\tLa muerte del Asegurado producida durante el periodo de subsidio por incapacidad temporal a cargo del ESSALUD, por causas distintas a accidente de trabajo o enfermedad profesional.',
    },
    {
      id: '4',
      question: '¿Qué es un accidente laboral?',
      description:'Se considera accidente laboral o de trabajo toda lesión orgánica o perturbación funcional causada en el centro de trabajo o con ocasión del trabajo, por acción imprevista, fortuita u ocasional de una fuerza externa, repentina y violenta que obra súbitamente sobre la persona del trabajador o debido al esfuerzo del mismo (de acuerdo con el Decreto Supremo Nº 009 - 97 - SA, artículo 2, inciso K).'
    },
    {
      id: '5',
      question: '¿Qué necesito para adquirir un seguro SCTR?',
      description:'Residir en el Perú Ser una empresa que por su actividad este obligada a contratar el SCTR y debe tener a su personal en la planilla. Asimismo, debes tener a la mano el formato (Excel) con los datos de tus trabajadores para poder realizar la cotización y posterior contratación. Para descargar el formato haz click aquí'
    },
    {
      id: '6',
      question: 'Tengo RUC 10 ¿Puedo adquirir un SCTR?',
      description:'Sí, puedes realizar igualmente la cotización y emisión. No olvides tener a la mano el formato (Excel) con los datos de los trabajadores. Puedes descargar dicho formato haciendo click aquí'
    },
    {
      id: '7',
      question: 'Soy independiente, solo tengo DNI o Carnet de Extranjería ¿Puedo contratar un SCTR?',
      description:'No, solo podemos emitir las pólizas a personas jurídicas, ya sea que cuenten con RUC 20 o RUC 10. En este caso, debes pertenecer a la planilla de alguna de estas personas jurídicas para acceder a esta póliza.'
    },
    {
      id: '8',
      question: 'Ya tengo un SCTR pero quiero renovarlo ¿Qué necesito?',
      description:'Las renovaciones las puedes pedir como máximo hasta 10 días posteriores al fin de tu última vigencia, si excedes ese plazo ya no podremos realizar una renovación, se considerará una venta nueva.\n' +
      '      Del mismo modo, necesitas tener a la mano el formato (Excel) con los datos de los trabajadores. Puedes descargar dicho formato haciendo click aquí'
    },
    {
      id: '9',
      question: '¿Cuánto tiempo tienen los asegurados/beneficiarios para reclamar los Beneficios? ',
      description:'La pensión de invalidez a favor del Asegurado se devengará desde el día siguiente de finalizado el período de 11 meses y 10 días consecutivos, correspondiente al subsidio por incapacidad temporal que otorga el Seguro Social de Salud (ESSALUD), siempre y cuando persista la condición de invalidez parcial o total, de naturaleza temporal o permanente.\n' +
      '      La pensión de Sobrevivencia, el plazo para la presentación de los beneficiarios es de 120 días calendario contados a partir de la fecha de fallecimiento o declaración judicial de muerte presunta del Asegurado.'
    },
  ],
  RENTA_VITALICIA: !isBussines ? [
    {
      id: '1',
      question: '¿Qué es una renta vitalicia?',
      description:
        'Es una de las alternativas de pensión del Sistema Privado de Pensiones, que le garantiza una Renta Vitalicia mensual durante toda su vida.',
    },
    {
      id: '2',
      question: '¿Desde cuándo y cómo recibo mi renta vitalicia?',
      description:
        'Si usted optó por una Renta Vitalicia inmediata, Protecta Security asume inmediatamente el pago de sus pensiones.\n' +
        'Si usted optó por una Renta Vitalicia Temporal o Diferida, quiere decir que la AFP se hará cargo del pago de sus pensiones temporalmente, por el plazo elegido al momento de firmar su jubilación. Terminado el plazo indicado, Protecta Security asume la responsabilidad del pago de forma directa.',
    },
    {
      id: '3',
      question: '¿Cómo  realizo el cambio de mi forma de pago de pensión?',
      description: 'Para solicitar dicho cambio se requieren los siguientes documentos:',
    },
    {
      id: '4',
      question: '¿Debo presentar mi certificado de Supervivencia?',
      description:
        'Si eres pensionista registrado con un DNI, estás exonerado de presentar tu certificado anual.\n' +
        '\n' +
        '  Si resides en el extranjero, deberás acercarte a la oficina consular de Perú en dicho país y luego visar el certificado por medio del Ministerio de Relaciones exteriores en Perú. Posteriormente, debes remitir el documento original de Certificado de Supervivencia a nuestras oficinas.',
    },
    {
      id: '5',
      question: '¿Qué hago si no he recibido el pago de mi pensión?',
      description:
        'En el caso de pensión de invalidez y sobrevivencia (esta última no derivada de una pensión de jubilación) el pago de la pensión se realiza a través de su AFP y se realiza entre el 24 y 28 de cada mes.\n' +
        'Para las pensiones de Jubilación y Sobrevivencia derivada de una pensión de jubilación, el pago de la pensión se realiza de acuerdo al cronograma establecido por Protecta Security, el cual se podrá encontrar ingresando a la Net Privada.',
    },
    {
      id: '6',
      question: '¿He perdido mi póliza, como puedo obtener una copia?',
      description:
        'Puedes ingresar a la Net Privada y descargar la copia que necesites. Si tienes alguna dificultad, puedes comunicarte con nosotros al 391-3000 (Lima) o 0801-12-1278 (Provincias), que gustosamente absolveremos tus dudas.',
    },
    {
      id: '7',
      question: '¿Qué debo hacer para solicitar el Reembolso de Gastos de Sepelio?',
      description:
        'El reembolso se realiza a la persona que asume el costo de los Gastos de Sepelio. La solicitud debe ser presentada en la AFP en la cual se encuentre afiliado el pensionista,  \n' +
        'para lo cual deberá presentar, entre otros documentos, las facturas y/o boletas de pago originales que acrediten el gasto realizado, así como el nombre, domicilio y documento de identidad de la persona que realizó el pago.\n' +
        '\n' +
        'Nota: Esta cobertura solo aplica para fallecimiento del titular de una póliza de invalidez o jubilación, asimismo, la compañía de seguros efectúa el reembolso siempre y cuando el pensionista se haya encontrado en la etapa vitalicia, si el fallecimiento ocurre en la etapa temporal es la AFP la que realiza el reembolso.',
    },
    {
      id: '8',
      question: '¿Las pensiones de mis beneficiarios son Vitalicias?',
      description:
        'Las pensiones de los beneficiarios son vitalicias, salvo en el caso de los hijos no inválidos, cuyas pensiones son temporales hasta que cumplan los dieciocho (18) años de edad, dicho beneficio podrá ser ampliado hasta los veintiocho (28) años de edad en caso que el beneficiario siendo menor de edad alcance los dieciocho (18) años y continúe con sus estudios de nivel básico o superior en forma satisfactoria e ininterrumpida según la Resolución SBS N°4831-2013.\n' +
        '\n' +
        'El carácter satisfactorio de los estudios se establece en función a la aprobación de todos los cursos o materias que forman parte del plan de estudios y que tienen carácter obligatorio en el periodo regular lectivo.',
    },
    {
      id: '9',
      question: '¿Qué es el periodo Garantizado?',
      description:
        'Es una cobertura adicional que asegura el pago de la pensión total acordada, por la cantidad de años elegidos. De ese modo, al contratar esa cobertura adicional, en caso usted falleciera durante el Periodo Garantizado, La compañía  le dará a sus beneficiarios o herederos (a falta de los primeros) el total de la renta, hasta el final de dicho periodo. Vencido el Período Garantizado, las pensiones de los beneficiarios serán de acuerdo a los porcentajes que la ley establece.',
    },
    {
      id: '10',
      question: '¿Mi renta vitalicia es irrevocable?',
      description:
        'Sí, es cierto. No es posible renunciar a tu renta vitalicia. Conforme a lo indicado por la regulación dictada por la Superintendencia de Banca, Seguros y Administradoras Privadas de Fondos de Pensiones (Inciso 21.a del artículo 21° de la Resolución N° 232-98-EF-SAFP)',
    },
    {
      id: '11',
      question:
        '¿Quién será el encargado de pagar mi pensión de invalidez y sobrevivencia a partir de junio del 2019?',
      description:
        'En el caso de pensión de invalidez y sobrevivencia (esta última no derivada de una pensión de jubilación), el encargado de realizar directamente el pago seguirá siendo la AFP.',
    },
    {
      id: '12',
      question:
        'Si recibo una pensión de Sobrevivencia de un pensionista por Jubilación ya fallecido, ¿quién será el encargado de realizar el pago de la pensión?',
      description:
        'En este caso, al ser pensiones derivadas de una pensión de jubilación, Protecta Security sera la encargada del pago de la pensión.',
    },
    {
      id: '13',
      question: '¿Cómo utilizo el Programa CLUB PROTECTA SECURITY?',
      description:
        'Si ya iniciaste sesión en la Net Privada, no se requiere una identificación adicional. Solo debes ir al menú “CLUB PROTECTA SECURITY” y elegir los descuentos especiales que seleccionamos para ti.',
    },
  ] :[],
  SOAT: !isBussines ? [
    {
      id: '0',
      question: '¿Qué debo hacer en caso de un Siniestro?',
      description:
        'Contáctate de inmediato con nuestra Central de Atención al Cliente, llamando al (01) 391 3000 en Lima y al 0801-1-1278 en Provincias, opción 1 – 1. Te brindaremos la asesoría que necesitas en estos momentos.\n' +
        'En el establecimiento de Salud que está atendiendo los heridos, muestra el certificado SOAT Protecta Security para activar la cobertura. Es importante que tengas presente que puedes asistir a cualquier centro de salud público o privado, siempre y cuando tu SOAT Protecta Security esté vigente.\n' +
        '\n' +
        '° Denuncia el accidente de tránsito con la autoridad policial y sométete al dosaje etílico cuando lo solicite la autoridad policial.',
    },
    {
      id: '1',
      question: '¿Quién puede realizar la solicitud de indemnización?',
      description:
        'El trámite de la indemnización es totalmente gratuito y es importante que lo realice directamente el agraviado o quien legalmente lo represente, en caso de menores de edad lo debe realizar directamente alguno de los padres o apoderados legales.',
    },
    {
      id: '2',
      question: '¿Cuánto tiempo tengo para reclamar los beneficios del SOAT de Protecta Security?',
      description:
        'Dos (02) años desde la ocurrencia del siniestro, según lo establecido por el Reglamento Nacional de Responsabilidad Civil y Seguros Obligatorios por Accidentes de Tránsito, aprobado mediante el Decreto Supremo N° 024-2002-MTC.',
    },
    {
      id: '3',
      question: '¿Es necesaria la denuncia policial para solicitar la cobertura del SOAT? ',
      description:
        'Luego de un accidente de tránsito, es necesario y obligatorio presentar la denuncia policial para iniciar el proceso de indemnización y reembolso de gastos médicos. Sin embargo, en el momento de la emergencia la prioridad siempre será solicitar la atención del lesionado; una vez superado el incidente, podrás regularizar la denuncia.',
    },
    {
      id: '4',
      question: '¿Qué puedo hacer si necesito cambiar el tipo de uso de mi SOAT?',
      description:
        'Para ese caso, solo corresponde adquirir un nuevo SOAT. Para ello, deberás visitarnos en cualquiera de nuestras oficinas a nivel nacional, traer consigo el certificado original en el caso sea un SOAT físico y realizar el trámite para adquirir un nuevo SOAT con el tipo de uso que necesitas. Recuerda que el color de la placa determina el tipo de uso de tu vehículo. Esto es importarte considerarlo frente a intervenciones policiales.',
    },
    {
      id: '5',
      question: '¿Cómo activar la cobertura de Gastos Médicos?',
      description:
        'Protecta Security realizará el pago de las facturas directamente al establecimiento de salud, . En caso el Asegurado haya realizado algún pago de manera directa al establecimiento de salud, la Compañía de Seguros procederá a reembolsar dichos gastos previa solicitud y evaluación. Para ello, es necesario conversar todos los comprobantes de pago.',
    },
    {
      id: '6',
      question: '¿Cuál es la diferencia entre Invalidez e Incapacidad?',
      description:
        ' La invalidez es un término médico que se refiere a la pérdida funcional de algún miembro o extremidad (brazo, pierna, mano, etc.) y la incapacidad es la consecuencia de dicha pérdida funcional, que imposibilita la realización de las actividades laborales o habituales.',
    },
    {
      id: '7',
      question: '¿Cómo tramito un duplicado de mi certificado SOAT?',
      description:
        'En caso requieras un duplicado del certificado SOAT (físico), debes contactarnos mediante nuestros canales de Servicio al Cliente para solicitarlo y luego acercarte a nuestras oficinas para presentar la Tarjeta de Propiedad del vehículo, copia de la denuncia policial por el robo o pérdida y el Documento Nacional de Identidad del titular del seguro. El trámite es personal y en caso de empresas se deberá acercar el mismo representante legal. Los duplicados de certificados SOAT no tienen costo alguno.',
    },
  ] : [
    {
      id: '0',
      question: '¿Qué es el SOAT?',
      description: 'El SOAT es un seguro obligatorio por Ley con la finalidad de asegurar la atención, de manera inmediata e incondicional, de las víctimas de accidentes de tránsito sean ocupantes o terceros no ocupantes de un vehículo automotor, que sufran lesiones corporales y/o muerte.',
    },
    {
      id: '1',
      question: 'Que datos necesito para comprar un SOAT?',
      description: ' Para comprar un SOAT electrónico a través de nuestro eCommerce, necesitarás lo siguiente:\n' +
      '° Datos del vehículo: Consignados en la Tarjeta de Propiedad \n' +
      ' ° Vigencia de póliza: La fecha de inicio de vigencia tiene que ser a partir del día siguiente del último día de vigencia del SOAT actual o el día de la fecha de compra\n' +
      ' ° Datos del contratante\n' +
      ' ° Correo electrónico al cual se enviara el certificado SOAT.\n' +
      ' ° Deberás elegir tu medio de pago.',
    },
    {
      id: '2',
      question: 'En cuanto tiempo recibiré el SOAT electrónico?',
      description: 'Luego de haber finalizado lacompra, y que se haya realizado el cargo en la tarjeta o haber realizado el pago en el Banco o por la banca por internet. Deberá esperar unos minutos para recibir su SOAT electrónico en el correo electrónico que registró al momento de la compra. Como máximo deberá llegar dentro de las 24 horas de culminado el proceso de compra y pago.',
    },

    {
      id: '3',
      question: 'Qué hacer si el SOAT Electrónico no llega al correo electrónico registrado?',
      description: 'Debe revisar la bandeja de correo electrónico, en la sección “correo no deseado o spam” y verificar si el correo se encuentra ahí. Recuerda que el certificado se envía automáticamente desde el correo soatdigital@protectasecurity.pe\n' +
      '\n' +
      'También puedes escribirnos a clientes@protectasecurity.pe desde el correo registrado en la compra, indicando los datos del vehículo y del contratante (placa, ruc, razón social). Si escribes desde otra dirección electrónica diferente al de la compra, deberás enviarnos un correo considerando los siguientes datos y adjuntos:\n' +
      '\n' +
      '  - Carta membretada con firma del Representante Legal.\n' +
      '- Indicar placa\n' +
      '- Indicar monto pagado\n' +
      '- DNI por ambos lados del Representante Legal',
    },
    {
      id: '4',
      question: '¿Qué se debe hacer en caso de un Siniestro?',
      description: ' Para hacer efectivo el seguro y atender a las víctimas no se necesita pronunciamiento de autoridad alguna.\n' +
      ' \n' +
      ' ° Deben contactarse de inmediato con nuestra Central de Atención al Cliente, llamando al (01) 391 3000 en Lima y al 0801-1-1278 en Provincias, opción 1 – 1. Les brindaremos la asesoría que necesitan en estos momentos.\n' +
      ' ° En el establecimiento de Salud que esta atendiendo los heridos, deben mostrar  el certificado SOAT Protecta Security para activar la cobertura. Es importante que tengan presente que pueden asistir a cualquier centro de salud público o privado, siempre y cuando su SOAT Protecta Security esté vigente.\n' +
      ' \n' +
      ' ° Denuncie el accidente de tránsito con la autoridad policial y deberán someterse al dosaje etílico, cuando lo solicite la autoridad policial.',
    },
    {
      id: '5',
      question: '¿Quién puede realizar la solicitud de indemnización?',
      description: 'El trámite de la indemnización es totalmente gratuito y es importante que lo realice directamente el agraviado o quien legalmente lo represente, en caso de menores de edad lo debe realizar directamente alguno de los padres o apoderados legales.',
    },
    {
      id: '6',
      question: '¿Cuánto tiempo tengo para reclamar los beneficios del SOAT de Protecta Security?',
      description: 'Dos (02) años desde la ocurrencia del siniestro, según lo establecido por el Reglamento Nacional de Responsabilidad Civil y Seguros Obligatorios por Accidentes de Tránsito, aprobado mediante el Decreto Supremo N° 024-2002-MTC.',
    },
    {
      id: '7',
      question: '¿Es necesaria la denuncia policial para solicitar la cobertura del SOAT?',
      description: ' Luego de un accidente de tránsito, es necesario y obligatorio presentar la denuncia policial para iniciar el proceso de indemnización y reembolso de gastos médicos. Sin embargo, en el momento de la emergencia la prioridad siempre será solicitar la atención del lesionado; una vez superado el incidente, podrás regularizar la denuncia.',
    },
    {
      id: '8',
      question: '¿Se puede realizar el cambio del tipo de uso del SOAT comprado?',
      description: ' Para ese caso, solo corresponde adquirir un nuevo SOAT. Para ello, deberan visitarnos en cualquiera de nuestras oficinas a nivel nacional, traer consigo el certificado original en el caso sea un SOAT físico y realizar el trámite para adquirir un nuevo SOAT con el tipo de uso que necesiten Recuerden que el color de la placa determina el tipo de uso del vehículo. Esto es importarte considerarlo frente a intervenciones policiales.',
    },
    {
      id: '9',
      question: '¿Cómo activar la cobertura de Gastos Médicos?',
      description: ' Protecta Security realizará el pago de las facturas directamente al establecimiento de salud, . En caso el Asegurado haya realizado algún pago de manera directa al establecimiento de salud, la Compañía de Seguros procederá a reembolsar dichos gastos previa solicitud y evaluación. Para ello, es necesario conversar todos los comprobantes de pago originales.',
    },
    {
      id: '10',
      question: '¿Cuál es la diferencia entre Invalidez e Incapacidad?',
      description: '  La invalidez es un término médico que se refiere a la pérdida funcional de algún miembro o extremidad (brazo, pierna, mano, etc.) y la incapacidad es la consecuencia de dicha pérdida funcional, que imposibilita la realización de las actividades laborales o habituales.',
    },
    {
      id: '11',
      question: '¿Cómo tramito¿amos el duplicado del certificado SOAT?',
      description: 'Si tu certificado es físico, deberás enviarnos los siguientes documentos por correo electrónico y si todo está conforme, en un plazo no mayor a 7 días útiles te estaremos enviando el certificado a la dirección de la empresa consignada en SUNAT.\n' +
      '\n' +
      '-Copia certificada de Denuncia Policial por pérdida o robo del certificado SOAT físico.\n' +
      '-Carta Membretada con firma del Representante Legal solicitando el duplicado\n' +
      '-Copia del DNI del Representante Legal\n' +
      '-Tarjeta de Propiedad de la Unidad\n' +
      '\n' +
      'Si adquiriste SOAT Digital y necesitas un reenvío de la constancia debes escribirnos a clientes@protectasecurity.pe desde el correo registrado en la compra, indicando los datos del vehículo y del contratante (placa, ruc, razón social). Si escribes desde otra dirección electrónica diferente al de la compra, deberás enviarnos un correo considerando los siguientes datos y adjuntos:\n' +
      '\n' +
      '- Carta membretada con firma de Representante Legal.\n' +
      '- Indicar placa\n' +
      '- Indicar monto pagado\n' +
      '- DNI por ambos lados del Representante Legal',
    },
    {
      id: '12',
      question: '¿Qué sucede si ya consumió el total de la cobertura que brinda el SOAT?',
      description: 'En caso se consuma el monto total de cobertura que brinda el SOAT Protecta Security para asistencias médicas, el paciente deberá asumir de forma particular los costos adicionales o emplear algún seguro médico particular o social. Por otro lado, algunos vehículos podrian contar con un seguro de responsabilidad civil el cuál podria activarse luego de culminada la cobertura de SOAT. En este caso, el paciente deberá coordinar directamente con el dueño del vehículo involucrado.',
    },
  ],
  VIDA_LEY: !isBussines ? [
    {
      id: '0',
      question: '¿Qué es un Seguro Vida Ley Trabajadores?',
      description:
        'Es un seguro de vida que contempla beneficios a los familiares de un trabajador ante su fallecimiento por muerte natural, accidental o invalidez total y permanente por accidente.  \n' +
        'El seguro de vida ley trabajadores es obligatorio, además debe ser contratado por el empleador y tiene cobertura desde el primer día de labores del trabajador.',
    },
    {
      id: '1',
      question: '¿Existen coberturas adicionales a la Ley?',
      description:
        'Si, brindamos coberturas adicionales para asegurados menores de 59 años, siempre y cuando haya sido solicitado.',
    },
    {
      id: '2',
      question: '¿Quiénes son mis beneficiarios si cuento con un seguro de Vida Ley Trabajadores?',
      description:
        'Son las personas en beneficio de las cuales se toma el Seguro y son:\n' +
        'a.  El cónyuge o conviviente del Asegurado (según lo estipulado en el código civil).\n' +
        'b. Los descendientes directos del Asegurado.\n' +
        'En caso de ausencia de los Beneficiarios indicados, son beneficiarios los padres y hermanos menores de dieciocho (18) años o hermanos incapacitados total y permanentemente del Asegurado.',
    },
    {
      id: '3',
      question: '¿Puedo mantener el seguro Vida Ley si dejo de trabajar en una empresa?',
      description:
        'Así es. En caso tengas un seguro Vida Ley y dejes tu lugar de trabajo, puedes solicitar el seguro de manera independiente en nuestra oficina principal, dentro de los primeros 30 días luego del cese. Este seguro se denomina Seguro de Vida Ley-extrabajador',
    },
    {
      id: '4',
      question: '¿Existe una edad máxima para contratar este seguro?',
      description:
        'Las normas emitidas no establecen una edad máxima para contratar las coberturas obligatorias de este seguro. No obstante, para las coberturas complementarias, en caso sean contratadas por el cliente, se ha establecido una edad máxima de ingreso y permanencia en el seguro.',
    },
    {
      id: '5',
      question: '¿Cuál es la diferencia entre obrero y empleado?',
      description:
        'Empleado: es una persona que  realiza labores predominantemente intelectuales o de oficina, lo cual implica que requiere un cierto grado de calificación. \n' +
        'Obrero: es una persona que  realiza laborales predominantemente operativas o manuales.',
    },
    {
      id: '6',
      question: '¿Cuál es la definición de Remuneración mensual?',
      description:
        'Se entiende por remuneración mensual aquella que se consigna en el libro de planillas y boletas de pago percibidas habitualmente por el trabajador, con exclusión de las gratificaciones, participaciones, compensación vacacional adicional y otras que por su naturaleza no se abonen mensualmente, hasta el tope de la Remuneración Máxima Asegurable, establecida para efectos del Seguro de Invalidez, Sobrevivencia y Gastos de Sepelio en el Sistema Privado de Pensiones.',
    },
  ] : [
    {
      id: '0',
      question: '¿Qué es un Seguro Vida Ley Trabajadores?',
      description:
      'Es un seguro de vida que contempla beneficios a los familiares de un trabajador ante su fallecimiento por muerte natural, accidental o invalidez total y permanente por accidente.  \n' +
      'El seguro de vida ley trabajadores es obligatorio, además debe ser contratado por el empleador y tiene cobertura desde el primer día de labores del trabajador.',
    },
    {
      id: '1',
      question: '¿Existen coberturas adicionales a la Ley?',
      description:
        'Si, brindamos coberturas adicionales para asegurados menores de 59 años, siempre y cuando haya sido solicitado.',
    },
    {
      id: '2',
      question: '¿Quiénes son mis beneficiarios si cuento con un seguro de Vida Ley Trabajadores?',
      description:
      'Son las personas en beneficio de las cuales se toma el Seguro y son:\n' +
      'a.  El cónyuge o conviviente del Asegurado (según lo estipulado en el código civil).\n' +
      'b. Los descendientes directos del Asegurado.\n' +
      'En caso de ausencia de los Beneficiarios indicados, son beneficiarios los padres y hermanos menores de dieciocho (18) años o hermanos incapacitados total y permanentemente del Asegurado.',
    },
    {
      id: '3',
      question: '¿Existe una edad máxima para contratar este seguro?',
      description:
        'Las normas emitidas no establecen una edad máxima para contratar las coberturas obligatorias de este seguro. No obstante, para las coberturas complementarias, en caso sean contratadas por el cliente, se ha establecido una edad máxima de ingreso y permanencia en el seguro.',
    },
    {
      id: '4',
      question: '¿Cuál es la diferencia entre obrero y empleado?',
      description:
      'Empleado: es una persona que  realiza labores predominantemente intelectuales o de oficina, lo cual implica que requiere un cierto grado de calificación. \n' +
      'Obrero: es una persona que  realiza laborales predominantemente operativas o manuales.',
    },
    {
      id: '5',
      question: '¿Cuál es la definición de Remuneración mensual?',
      description:
        'Se entiende por remuneración mensual aquella que se consigna en el libro de planillas y boletas de pago percibidas habitualmente por el trabajador, con exclusión de las gratificaciones, participaciones, compensación vacacional adicional y otras que por su naturaleza no se abonen mensualmente, hasta el tope de la Remuneración Máxima Asegurable, establecida para efectos del Seguro de Invalidez, Sobrevivencia y Gastos de Sepelio en el Sistema Privado de Pensiones.',
    },
    {
      id: '6',
      question: '¿Dónde realizo el pago de la prima? ',
      description: 'El Pago lo debe realizar a través de nuestras cuentas recaudadoras de los bancos BBVA y BCP.\n' +
      '\n' +
      'Para al BBVA, debera utilizar la opción de Pago de Servicios / Protecta - Prima Soles\n' +
      'Para el BCP, deberá utilizar la opción de Pago de Servicios / Protecta SA - Prima Soles',
    },
    {
      id: '7',
      question: '¿Qué debo hacer para incluir un nuevo trabajador a mi póliza vigente?',
      description: 'Se debe enviar la planilla del nuevo trabajador en el formato establecido por Protecta Security, indicando la fecha de inicio de labores, esto debe efectuarse durante el mes de la incorporación del trabajador.',
    },
    {
      id: '8',
      question: '¿Existe una edad máxima para este seguro? ¿Cuál es?',
      description: 'Las normas emitidas no establecen una edad máxima asegurable para las coberturas obligatorias. No obstante, para las coberturas complementarias, en caso sean contratadas por el cliente se ha establecido una edad máxima de ingreso y permanencia en el seguro.',
    },
    {
      id: '9',
      question: '¿Qué documentos debo presentar para solicitar la cobertura de Vida Ley?',
      description: 'En caso de ocurrencia del siniestro objeto de la cobertura, el Contratante, Asegurado o Beneficiario deberá dar aviso del siniestro a Protecta Security, y el Asegurado o Beneficiario  deberá solicitar la cobertura y presentar los siguientes documentos, en original o copia con certificación de reproducción notarial (antes copia legalizada):\n' +
      '\n' +
      'En caso de Muerte Natural:\n' +
      '\n' +
      'Acta y Certificado Médico de Defunción del Asegurado.\n' +
      'Tres (3) últimas boletas de pago del Asegurado.\n' +
      'Declaración Jurada de beneficiarios (con firma del Asegurado certificada notarialmente, o por el Juez de Paz), o Testamento por escritura pública (solo si este es posterior a la Declaración Jurada de beneficiarios). En el caso del Testamento solo tendrán derecho al seguro los beneficiarios designados en las disposiciones legales vigentes.\n' +
      'Sucesión Intestada en el supuesto caso de no existir una Declaración Jurada de beneficiarios o Testamento por escritura pública.\n' +
      'Documento de Identidad o Partida de Nacimiento de los Beneficiarios.\n' +
      'En caso de Muerte Accidental adicionalmente a los documentos por Muerte Natural:\n' +
      '\n' +
      'Copia certificada de Atestado y/o Parte Policial Completo, según corresponda.\n' +
      'Protocolo de Necropsia (de haberse practicado).\n' +
      'En caso de Invalidez Total y Permanente por Accidente:\n' +
      '\n' +
      'Documento de identidad del Asegurado.\n' +
      'Certificado de Invalidez Total y Permanente expedido por el Ministerio de Salud o los Servicios de Seguridad Social o Dictamen de Invalidez Total y Permanente expedido por la COMAFP (Comité médico de las AFP) y/o COMEC (Comité médico de la SBS).\n' +
      'Tres (3) últimas boletas de pago del Asegurado.',
    },
    {
      id: '10',
      question: '¿Cuáles son las exclusiones de la póliza de Vida Ley?',
      description: 'Los montos de los beneficios de esta póliza no se conceden si el Fallecimiento o Invalidez Total y Permanente del Asegurado se debe directamente, total o parcialmente a las siguientes causas:\n' +
      '\n' +
      'Suicidio, intento de suicidio o autolesión por parte del Asegurado, salvo que hubiesen transcurrido al menos dos (2) años completos e ininterrumpidos desde la fecha de celebración del seguro.',
    },
    {
      id: '11',
      question: '¿Cuándo termina la Póliza de Vida Ley?',
      description: 'La Póliza o Contrato de Seguro terminará al ocurrir la siguiente situación:\n' +
      '\n' +
      'Al vencimiento del plazo de vigencia establecido en la Póliza.',
    },
  ],
  SEGURO_ACCIDENTES: [
    {
      id: '0',
      question: '¿Qué es un seguro de Accidentes Personales?',
      description:
        'Se trata de un seguro que brinda coberturas en caso de que se produzca la Muerte Accidental o coberturas adicionales por accidentes. Las Sumas Aseguradas serán establecidas en las Condiciones Particulares y será pagada por la Compañía a los Asegurados o Beneficiarios, según corresponda.',
    },
    {
      id: '1',
      question: '¿Quiénes pueden adquirir este tipo de Seguro?',
      description:
        'Protecta Security ofrece este seguro a personas jurídicas (empresas y centro de estudios) y personas naturales para que puedan protegerse y/o proteger a sus familiares en caso de accidentes.',
    },
    {
      id: '2',
      question: '¿Cómo reporto un accidente (emergencia)?',
      description:
        'Contáctate de inmediato en cualquier día y hora con nuestra Central de Atención al Cliente llamando al (01) 391 3000 en Lima o al 0801-1-1278 en Provincias, opción 1 – 2. Te brindaremos la asesoría que necesitas en ese momentos.',
    },
    {
      id: '3',
      question: '¿Qué necesito hacer o presentar para realizar una atención ambulatoria?',
      description:
        'Para el caso de la Cobertura de Gastos de Curación por Accidente, el Asegurado tendrá las siguientes dos (02) alternativas de atención:\n' +
        '\n' +
        'Atención en Centros Médicos Afiliados:​ Se deberá presentar el Documento de Identidad (DNI) del Asegurado en el Centro Médico Afiliado, al momento del ingreso por Emergencia.\n' +
        'Atención por Reembolso:\n' +
        'Los Asegurados que cuenten con este seguro, podrán atenderse en cualquier clínica u hospital de su preferencia, todos los días de la semana, en cualquier horario, hasta por el monto de la Suma Asegurada.\n' +
        'El Asegurado deberá dar aviso a Protecta Security y entregarnos la siguiente documentación:\n' +
        '\n' +
        'Copia del Documento de Identidad (DNI) del Alumno Asegurado.\n' +
        'Original de los comprobantes de pago de la Clínica u Hospital, a nombre del Alumno Asegurado, o del padre o tutor legal.\n' +
        'Original de las recetas y órdenes médicas para exámenes.\n' +
        'Original de los informes de resultados de exámenes.\n' +
        'Copia del Informe del médico que prestó los primeros auxilios al Asegurado, expresando: causas del accidente y sus consecuencias conocidas y probables.',
    },
    {
      id: '4',
      question: '¿Qué necesito hacer o presentar si necesito hospitalización?',
      description:
        'En caso de sufrir un accidente, solo deberá acercarse con su documento de identidad a la clínica u hospital e  informar que tienen Seguro por Accidentes con Protecta Security, ellos validaran la cobertura.',
    },
    {
      id: '5',
      question: '¿Qué necesito hacer para continuar con mi atención por accidente?',
      description:
        'Para continuar con su atención, solo deberá acercarse con su documento de identidad a la clínica u hospital e  informar que tienen Seguro por Accidentes con Protecta Security, ellos validaran la cobertura.',
    },
    {
      id: '6',
      question: '¿Cuántas consultas de continuación como máximo puedo tener en un tratamiento?',
      description:
        'La continuidad de consultas se da hasta agotar el monto de la cobertura, en primera instancia la clínica u hospital puede darle el saldo de la cobertura o en su defecto puedes comunicarte con nosotros al buzón clientes@protectasecurity.pe, enviando copia de tu documento de identidad para poder ayudarte',
    },
  ],
  SEGURO_DESGRAVAMEN: !isBussines ? [
    {
      id: '0',
      question: '¿Qué es un Seguro de Desgravamen ?',
      description:
        'El Seguro de Desgravamen tiene por objeto pagar la deuda que el Asegurado mantiene con una entidad del Sistema Financiero, ante la eventualidad de su fallecimiento o de quedar inválido, beneficiándose de esta manera tus herederos, quienes se verán liberados de la obligación de pago del crédito.',
    },
    {
      id: '1',
      question: '¿Qué necesito para adquirir el seguro?',
      description:
        'Podrá asegurarse bajo la presente Póliza cualquier persona que goce de Buena Salud, siempre que cumpla con los requisitos de edad establecidos en las Condiciones Particulares de la Póliza.\n' +
        'La edad declarada por el Asegurado debe comprobarse con documento oficial en los casos que así lo juzgue necesario la Compañía, después de producido el Siniestro.',
    },
    {
      id: '2',
      question: '¿Qué debo hacer en caso de siniestro?',
      description:
        'En caso de ocurrencia del siniestro, dar aviso inmediatamente a la Plataforma de Atención al Cliente de Protecta Security, a través de los siguientes canales de comunicación:\n' +
        'Atención Telefónica: 391-3000 / Provincias: 0-801-1-1278\n' +
        'Correo electrónico: clientes@protectasecurity.pe\n' +
        'Web: www.protectasecurity.pe\n' +
        'Atención Presencial: Av. Domingo Orué 165, 8vo. Piso, Surquillo, Lima – Perú\n' +
        'Horario de atención, de lunes a viernes de 9am a 6pm',
    },
    {
      id: '3',
      question: '¿Cuánto tiempo tengo para reclamar los beneficios?',
      description:
        'El Asegurado o el Beneficiario deben dar aviso del siniestro a Protecta Security, dentro de los siete (7) días siguientes a la fecha en que se tenga conocimiento de la ocurrencia del siniestro.',
    },
    {
      id: '4',
      question:
        '¿Este seguro me puede beneficiar en algo más que asumir mi deuda frente a mi fallecimiento?',
      description:
        'Dependiendo las coberturas contratadas si la condición de la póliza asegura el monto inicial del préstamo (o cualquier producto financiero que corresponda) y el fallecimiento se produce, por ejemplo, a mitad del periodo de financiamiento, Protecta Security no solo asumirá la deuda frente a la entidad financiera, sino que también otorgará a los beneficiarios el saldo restante (llamado también saldo insoluto)',
    },
  ]: [],
  ACCIDENTES_PERSONALES: !isBussines ? [
    {
      id: '0',
      question: '¿Qué es un seguro de Accidentes Personales?',
      description:
        'Se trata de un seguro que brinda coberturas en caso de que se produzca la Muerte Accidental o coberturas adicionales por accidentes. Las Sumas Aseguradas serán establecidas en las Condiciones Particulares y será pagada por la Compañía a los Asegurados o Beneficiarios, según corresponda.',
    },
    {
      id: '1',
      question: '¿Quiénes pueden adquirir este tipo de Seguro?',
      description:
        'Protecta Security ofrece este seguro a personas jurídicas (empresas y centro de estudios) y personas naturales para que puedan protegerse y/o proteger a sus familiares en caso de accidentes.',
    },
    {
      id: '2',
      question: '¿Cómo reporto un accidente (emergencia)?',
      description:
        'Contáctate de inmediato en cualquier día y hora con nuestra Central de Atención al Cliente llamando al (01) 391 3000 en Lima o al 0801-1-1278 en Provincias, opción 1 – 2. Te brindaremos la asesoría que necesitas en ese momentos.',
    },
    {
      id: '3',
      question: '¿Qué necesito hacer o presentar para realizar una atención ambulatoria?',
      description:
      'Para el caso de la Cobertura de Gastos de Curación por Accidente, el Asegurado tendrá las siguientes dos (02) alternativas de atención:\n' +
      '\n' +
      'Atención en Centros Médicos Afiliados:​ Se deberá presentar el Documento de Identidad (DNI) del Asegurado en el Centro Médico Afiliado, al momento del ingreso por Emergencia.\n' +
      'Atención por Reembolso:\n' +
      'Los Asegurados que cuenten con este seguro, podrán atenderse en cualquier clínica u hospital de su preferencia, todos los días de la semana, en cualquier horario, hasta por el monto de la Suma Asegurada.\n' +
      'El Asegurado deberá dar aviso a Protecta Security y entregarnos la siguiente documentación:\n' +
      '\n' +
      'Copia del Documento de Identidad (DNI) del Alumno Asegurado.\n' +
      'Original de los comprobantes de pago de la Clínica u Hospital, a nombre del Alumno Asegurado, o del padre o tutor legal.\n' +
      'Original de las recetas y órdenes médicas para exámenes.\n' +
      'Original de los informes de resultados de exámenes.\n' +
      'Copia del Informe del médico que prestó los primeros auxilios al Asegurado, expresando: causas del accidente y sus consecuencias conocidas y probables.',
    },
    {
      id: '4',
      question: '¿Qué necesito hacer o presentar si necesito hospitalización?',
      description:
        'En caso de sufrir un accidente, solo deberá acercarse con su documento de identidad a la clínica u hospital e  informar que tienen Seguro por Accidentes con Protecta Security, ellos validaran la cobertura.',
    },
    {
      id: '5',
      question: '¿Qué necesito hacer para continuar con mi atención por accidente?',
      description:
        'Para continuar con su atención, solo deberá acercarse con su documento de identidad a la clínica u hospital e  informar que tienen Seguro por Accidentes con Protecta Security, ellos validaran la cobertura.',
    },
    {
      id: '6',
      question: '¿Cuántas consultas de continuación como máximo puedo tener en un tratamiento?',
      description:
        'La continuidad de consultas se da hasta agotar el monto de la cobertura, en primera instancia la clínica u hospital puede darle el saldo de la cobertura o en su defecto puedes comunicarte con nosotros al buzón clientes@protectasecurity.pe, enviando copia de tu documento de identidad para poder ayudarte',
    },
  ]: [],
};
