import { Routes } from '../../../routes';
import { dateLastMonth } from '../../../util/Util';

export const Strings = {
  INFO_PASSWORD:
    'Si no reconoces estos datos de contacto o están desactualizados, contáctate con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  PASSWORD_EXCEEDED_TRY:
    'Puedes volver a intentarlo mañana. Queremos que disfrutes de Net Privada, comunícate con nuestra central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias y te ayudaremos con gusto.',
  PASSWORD_EXCEEDED_TRY_TITlE: 'Excediste los intentos en el día',
  SEND_EXCEEDED_TRY_TITLE: 'Excediste el límite de intentos',
  SEND_EXCEEDED_TRY:
    'Inténtalo más tarde. Queremos que disfrutes de Net Privada, comunícate con nuestra central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias y te ayudaremos con gusto.',

  INFO_PHONE:
    'Si no reconoces este número, contáctate con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  INFO_EMAIL:
    'Si no reconoces este e-mail, contáctate con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_NOT_VALID:
    'No te encontramos en nuestra base de datos por favor ponte en contacto con nuestra Central de Servicio al Cliente: (01) 391-3000 Lima y 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_INVALID_POLICIES:
    'Hola,\n' +
    'No te encontramos en nuestra base de clientes activos. Te invitamos a conocer más de nuestros seguros en www.protectasecurity.pe o si lo prefieres comunícate a nuestra Central de Servicio al Cliente al 391-3000 en Lima o 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_NOT_FOUND: 'Lo sentimos, no encontramos un usuario con ese DNI, Te invitamos a registrarte.',
  USER_BLOCKED: 'Lo sentimos, el usuario se encuentra bloqueado.',
  USER_INCOMPLETE:
    'Necesitamos contar con un correo electrónico registrado en nuestra base de datos para continuar con el proceso. Ponte en contacto con nuestra Central de Servicio al Cliente al 01-391-3000 en Lima o al 0-801-1-1278 en Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  USER_COMPLETED: 'Ya se encuentra registrado en la plataforma Net Privada.',
  USER_ERROR_TIME:
    'Lamentablemente este canal es exclusivo para asegurados y contratantes. Si deseas más información puedes contactarte al teléfono 01 3913005 o al correo canalcorporativo@protectasecurity.pe',
  ERROR_MESSAGE_LOGIN:
    'La contraseña ingresada no es válida. Verifícala e intenta de nuevo, o dale click en “Olvidé mi contraseña”.',
  ERROR_USER_BLOCKED: 'Usuario bloqueado.',
  ERROR_REGISTER_BUSINESS: 'Error al realizar el registró de la empresa', // todo mensaje
  ERROR_GET_URL: 'Error al obtener la url para subir el archivo',
  ERROR_UPLOAD_FILE: 'No se pudo subir el archivo adjuntado',
  ERROR_CREATE_BUSINESS: 'Error al crear la empresa',
  USER_INVALID_ACCESS:
    'Estamos trabajando para restablecer nuestros sistemas muy pronto. Por favor ponte en contacto con nuestra Central de Servicio al Cliente: (01) 391-3000 Lima y 0-801-1-1278 Provincias de Lunes a Viernes de 9a.m. a 6p.m, excepto feriados.',
  LIMIT_EXCEEDED: 'LIMIT EXCEEDED',
  LOGIN_EXCEEDED: 'Límite de intentos excedido. Inténtelo más tarde',
};
export const AuthValues = {
  NO_CUSTOMER: 'NON CUSTOMER',
  CUSTOMER_INVALID_POLICIES: 'CUSTOMER INVALID POLICIES',
  INVALID_CONTACT: 'INVALID CONTACT DETAILS',
};

const lastMonth = dateLastMonth();
const stylesMessage = `'font-size : 110%; text-decoration: none;'`;
const commonFilter = `
<u style=${stylesMessage}>
<li> Pólizas vigentes y cuya fecha de fin de vigencia sea mayor a ${lastMonth}  </li>
<li> Pólizas cuyos roles sean diferentes de 'Intermediario'  </li>
<li> No se muestran las pólizas anuladas  </li>
</u>
`;
export const policyFilterMessage = {
  rentaTotal: `
  <p style=${stylesMessage}>
  Se muestran únicamente las pólizas vigentes
  </p>`,
  ahorroTotal: `
  <p style=${stylesMessage}>
  Se muestran únicamente las pólizas vigentes
  </p>`,
  rentasVitalicias: `
  <u style=${stylesMessage}>
  <li> Pólizas vigentes, cuyos roles sean diferentes de 'Intermediario'  </li>
  <li> No se muestran las pólizas anuladas  </li>
  </u>
  `,
  desgravamen: commonFilter,
  vidaLey: commonFilter,
  vidaIndividual: `
  <u style=${stylesMessage}>
  <li> Pólizas vigentes y cuya fecha de fin de vigencia sea mayor a ${lastMonth} </li>
  <li> No se muestran las pólizas anuladas  </li>
  <li> Pólizas de rol Contratante </li>
  </u>
  `,
  vidaDevolucion: `
  <u style=${stylesMessage}>
  <li> Pólizas con estado diferente de 'Anulado' y diferente de 'No Vigente'  </li>
  <li> Pólizas de rol Contratante </li>
  </u>
  `,
  sepelio: commonFilter,
  sctr: commonFilter,
  accidentesPersonales: commonFilter,
  soat: `
  <u style=${stylesMessage}>
  <li> Pólizas vigentes y las que vencieron desde el ${lastMonth}.  </li>
  <li> Pólizas de rol Contratante  </li>
  <li> No se muestran las pólizas anuladas  </li>
  </u>
  `,
};

export const PRODUCT_VIDA_DEVOLUCION_PROTECTA = 'Vida Devolución Protecta';

export const menuCode = {
  RENTA_VITALICIA: '20000001',
  RENTA_TOTAL: '20000008',
  AHORRO_TOTAL: '20000003',
  SOAT: '20000007',
  VIDA_LEY: '20000004',
  VIDA_DEVOLUCION: '20000011',
  VIDA_INDIVIDUAL: '20000010',
  ACCIDENTES_PERSONALES: '20000006',
  DESGRAVAMEN: '20000002',
  VIDA_CREDITO :'20000013',
  SCTR : '20000005',
};

export const services = {
  20000001: {
    icon: 'productoRenta',
    name: 'Rentas Vitalicias',
    page: Routes.HOME_PRODUCT_RRVV,
  },

  20000002: {
    icon: 'productoDesgravament',
    name: 'Desgravamen',
    page: Routes.HOME_PRODUCT_DESGRAVAMEN,
  },
  20000010: {
    icon: 'bag',
    name: 'Vida Individual de Corto Plazo',
    page: Routes.HOME_PRODUCT_VIDAINDIVIDUAL,
  },
  20000004: {
    icon: 'bag',
    name: 'Vida Ley',
    page: Routes.HOME_PRODUCT_VIDALEY,
  },

  20000005: {
    icon: 'productoSctr',
    name: 'SCTR',
    page: Routes.HOME_PRODUCT_SCTR,
  },

  20000006: {
    icon: 'productoAccidente',
    name: 'Accidentes Personales',
    page: Routes.HOME_PRODUCT_ACCIDENTES,
  },
  20000007: { icon: 'productoSoat', name: 'SOAT', page: Routes.HOME_PRODUCT_SOAT },

  20000008: {
    icon: 'productoRenta',
    name: 'Renta Total Protecta',
    page: Routes.HOME_PRODUCT_RENTATOTAL,
  },

  // 752: {
  //   icon: 'productoRenta',
  //   name: 'Ahorro Total',
  //   page: Routes.HOME_PRODUCT_AHORROTOTAL,
  // },

  20000003: {
    icon: 'productoRenta',
    name: 'Ahorro Total Protecta',
    page: Routes.HOME_PRODUCT_AHORROTOTAL,
  },

  20000011: {
    icon: 'productVDP',
    name: PRODUCT_VIDA_DEVOLUCION_PROTECTA,
    page: Routes.HOME_PRODUCT_LIFE_DEVOLUTION,
  },
  20000013: {
    icon: 'productVCF',
    name: 'Vida Crédito Fácil',
    page: Routes.HOME_PRODUCT_LIFE_CREDIT,
  },
};

export const CodeOptProduct = {
  BLT: 'BLT',
  DCD: 'DCD',
  DCP: 'DCP',
  DCG: 'DCG',
  CDA: 'CDA',
  DCDVI: 'DCDVI',
  CDLA: 'CDLA',
  CICA: 'CICA',
  CPS: 'CPS',
  CP: 'CP',
  CMP: 'CMP',
  DP: 'DP',
  LDP: 'LDP',
  CDG: 'CDG',
  ADB: 'ADB',
  CPC: 'CPC',
  CGS: 'CGS',
  GLC: 'GLC',
  INCB: 'INCB', // Inclusion beneficiario
  EXCB: 'EXCB', // Exclusion beneficiario
  IBGS: 'IBGS', // Inclusion beneficiario gasto sepelio
  EBGS: 'EBGS', // Exclusion beneficiario gato sepelio
  MDT: 'MDT', // Modificación de tarjeta para cobro recurrrente
  HCT: 'HCT', // Historial cambio de tarjeta para cobro recurrrente
  CUP: 'CUP', // Cupones Pendientes
};

export const DescriptionForm = {
  CDA: 'Constancia de Asegurado',
  CMP: 'Cambio de modalidad de pago',
  ADB: 'Modificación de beneficiarios',
  CGS: 'Modificación de Beneficiarios de gasto de Sepelio',
  GLC: 'Geolocalización de clínicas',
  HCT: 'Historial de cambio de tarjeta para cobro recurrrente',
  MDT: 'Modificación de tarjeta para cobro recurrrente',
  CPC: 'Cambio de % de Capital asegurado',
  CUP: 'Cupones Pendientes',
};
