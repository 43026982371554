import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import cloneDeep from 'loadsh/cloneDeep';

import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { Coverage } from '../../shared/constants/Coverage';
import { Routes } from '../../../routes';
import {
  getMaxLenghCuentasByType,
  getOptionsToProduct,
  getMaxLengthDocument,
  validateAllFields,
  validateFieldForm,
  getValueDocumentWord,
  capitalizeSentence,
  dateNowFormatted,
  fixStructBase64,
  getProductInfo,
} from '../../../util/Util';
import { menuCode, policyFilterMessage } from '../../shared/constants/Strings';
import { OTHER_BANKS, userDefault } from '../../../util/Constant';

import PSBodyProduct from '../../components/PSBodyProduct';
import { GAProvider } from '../../components/PSAnalytics';
import { PSInfoProblems } from '../../components/molecules';
import { formAdb, formCmp, formCpc, listParent } from '../../shared/constants/Forms';
import BeneficiaryRents from '../../shared/services/BeneficiaryRents';
import { Beneficiary } from '../../shared/model/Beneficiary';
import { createErrorPage } from '../../redux/auth';

import GenericConstancyServices from '../../shared/services/GenericConstancyServices';

import './index.scss';

const img = 'product-AhorroTotal';
const imgMobile = 'product-AhorroTotal-mobile';

const headerTableFather = [
  { elementProp: 'shortDescription', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'prima', headerColumn: 'Prima' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

const formCgs = [
  { field: 'poliza', label: 'Nº de Póliza:', type: 'input', value: '' },
  { field: 'afiliado', label: 'Contratante:', type: 'input', value: '' },
  { field: 'tipoDocument:', label: 'Tipo de documento:', type: 'select', value: '' },
  { field: 'nroDocument', label: 'Nº de documento:', type: 'input', value: '' },
  {
    field: 'cobrante:',
    label: 'Nombres y Apellidos del nuevo beneficiario:',
    className: 'text-uppercase',
    type: 'input',
    value: '',
  },

  { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '' },
  { field: 'vinculo', label: 'Vínculo:', type: 'input', value: '' },
  { field: 'capitalA', label: '% de Capital Asegurado:', type: 'number', value: '' },
  {
    field: 'documents',
    label: 'Adjuntar DNI de los nuevos beneficiarios:',
    type: 'drop',
    value: '',
    info: '(Se deben adjuntar los DNI de todos los nuevos beneficiarios)',
  },
];

const defaultBeneficiary = {
  typeDocument: '',
  nroDocument: null,
  fullName: '',
  parent: '',
  percentage: null,
  date: null,
};

export default function HomeServices(props) {
  const [loadingOption, setLoadingOption] = useState(true);
  const [loadingBeneficiary, setLoadingBeneficiary] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const attributes = userInfo && userInfo.attributes ? userInfo.attributes : userDefault;
  const fullName = `${attributes?.middleName} ${attributes?.familyName} ${attributes?.givenName}`;
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [fileBase64, setFileBase64] = useState({});
  const {
    product: menuId,
    productDescription: menuDescription,
    policies
  } = getProductInfo(userInfo?.productsDetail, menuCode.AHORRO_TOTAL);
  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    listOfBeneficiary: [],
    currentBeneficiary: -1,
  });
  const [beneficiaryInfoInit, setBeneficiaryInfoInit] = useState([]);
  const [madeChanges, setMadeChanges] = useState(false); // form ADB
  const [currentNewBeneficiary, setCurrentBeneficiary] = useState({ ...defaultBeneficiary });
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);
  const [indexDelete, setIndexDelete] = useState(-1);
  const dispatch = useDispatch();
  const ga = React.useContext(GAProvider);
  const [options, setOptions] = useState([]);

  const [form, setForm] = useState({
    CMP: cloneDeep(formCmp),
    ADB: cloneDeep(formAdb), // Actualización beneficiario
    CPC: cloneDeep(formCpc),
    CGS: formCgs,
  });

  const policiesMessage = policyFilterMessage.ahorroTotal;

  const getOptionsAvailable = (rol = 'Asegurado') => {
    const option = [];
    if (process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_CRONOGRAMA_PAGO === 'true') {
      option.push('CP');
    }

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_CUADRO_VALORES_GARANTIZADOS === 'true'
    ) {
      option.push('CDG');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_DESCARGA_POLIZA === 'true') {
      option.push('DP');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_CAMBIO_MODALIDAD_PAGO === 'true') {
      option.push('CMP');
    }

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_ACTUALIZACION_BENEFICIARIOS === 'true'
    ) {
      option.push('ADB'); // Modificación de benenficiarios
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_PORCENTAJE === 'true') {
      option.push('CPC');
    }

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_AHORRO_TOTAL_CONSTANCIA_ASEGURADO === 'true' &&
      rol.trim() !== 'Contratante'
    ) {
      option.push('CDA');
    }
    setOptions(option);
  };
  const onSelectBeneficiary = (index) => {
    beneficiaryInfo.currentBeneficiary = index;
    setBeneficiaryInfo({ ...beneficiaryInfo });
  };

  const clearForms = (code) => {
    if (code) {
      form[code].forEach((item) => {
        if (!item.readOnly) {
          item.value = '';
          item.error = '';
          if (item.field === 'addField') {
            item.fieldAdd = 0;
            item.disabled = false;
          }
        }
      });
      const lasFieldDefaultIndex = form[code].findIndex((item) => {
        return item.lastFieldDefault;
      });
      if (lasFieldDefaultIndex !== -1 && form[code].length > lasFieldDefaultIndex + 4) {
        const numberOfFields = form[code][lasFieldDefaultIndex].numberFields;
        form[code].splice(lasFieldDefaultIndex + 1, numberOfFields);
      }
      setForm({ ...form });
    }
    setTimeout(() => {
      setCurrentCode(null);
    }, 1000);
  };

  const onChangeModePage = (e, index, code) => {
    let value = e.target ? e.target.value : e;
    const { maxLength } = form[code][index];
    const { maxValue } = form[code][index];
    const { minValue } = form[code][index];

    let isValid = true;
    if (maxLength) {
      if (value.length > maxLength) {
        isValid = false;
      }
    }

    if (maxValue) {
      if (value > maxValue) {
        isValid = false;
      }
    }

    if (isValid) {
      form[code][index].value = value;

      if (form[code][index].field === 'banco') {
        let indexUpdate = 7;

        if (value !== 'otros') {
          form[code] = form[code].filter((item) => {
            return item.field !== 'otroBanco';
          });
          // Eliminar CCI
          form[code][8].onlySelect = true;
          form[code][8].required = false;
          // form[code][8].required = true; // show CCI
        } else {
          form[code].splice(5, 0, {
            field: 'otroBanco',
            label: 'Nombre del banco:',
            type: 'select',
            value: '',
            required: true,
            options: [...OTHER_BANKS],
          });
          indexUpdate += 1;
          form[code][indexUpdate + 1].required = true;
          form[code][indexUpdate + 1].onlySelect = false; // hidden CCI
        }
        form[code][indexUpdate + 1].value = '';
      }
      if (
        form[code][index].field === 'otroBanco' ||
        form[code][index].field === 'banco' ||
        form[code][index].field === 'tipoDeCuenta'
      ) {
        const indexAccount = form[code].findIndex((item) => item.field === 'account');
        const bankG = form[code].find((item) => item.field === 'banco')?.value;
        const typeAccount = form[code].find((item) => item.field === 'tipoDeCuenta')?.value;
        const bank =
          bankG === 'otros' ? form[code].find((item) => item.field === 'otroBanco')?.value : bankG;

        form[code][indexAccount].value = '';
        form[code][indexAccount].error = '';
        form[code][indexAccount].maxLength = getMaxLenghCuentasByType(bank, typeAccount); // todo replace dynamic
      }

      if (
        form[code][index].field === 'tipoDocument' ||
        form[code][index].field === 'typeDocument' ||
        form[code][index].field.includes('tipoDocument')
      ) {
        form[code][index + 1].value = '';
        form[code][index + 1].maxLength = getMaxLengthDocument(value);
      }

      if (form[code][index].field === 'nroDocument') {
        form[code][index + 1].value = '';
        form[code][index + 2].value = '';
        form[code][3].info = '';
        form[code][3].boldInfo = false;
        form[code][3].error = value.length === maxLength ? '' : form[code][3].error;
      }

      if (form[code][index].field === 'percentage') {
        value = parseFloat(value);
        form[code][index].value = value.toString();

        const indexSave = form[code].findIndex((item) => item.field === 'saveField');
        if (minValue && value < minValue) {
          form[code][index].error = `Ingrese un valor mayor a ${minValue - 1}`;
          form[code][indexSave].disabled = true;
        } else {
          form[code][index].error = '';
        }
      }
      setForm({ ...form });

      if (code === 'ADB' && !form[code][index].readOnly) {
        currentNewBeneficiary[form[code][index].field] = value;
        setCurrentBeneficiary({ ...currentNewBeneficiary });
      }
    }
  };

  const deleteRecord = (code = 'IBGS', index) => {
    const field = form[code][index];

    const numbersDelete = field.numberFields;

    form[code].splice(index - numbersDelete + 1, numbersDelete);

    const initialIndex = form[code].findIndex((item) => {
      return item.field === 'addField';
    });
    form[code][initialIndex].fieldAdd = form[code][initialIndex].fieldAdd - 1;
    form[code][initialIndex].disabled = false;

    if (code.trim() === 'ADB') {
      sumBeneficiaries();
    }
    setForm({ ...form });
  };

  const addRegisto = (code = 'IBGS') => {
    const FieldsToAdd = {
      ADB: [
        {
          field: 'typeDocument',
          label: 'Tipo de documento:',
          type: 'select',
          value: '',
          required: true,
          options: [
            { label: 'Seleccione', value: '' },
            { label: 'DNI', value: 'D.N.I' },
          ],
        },
        {
          field: 'nroDocument',
          label: 'Nº de documento:',
          type: 'number',
          value: '',
          required: true,
          loading: false,
        },
        {
          field: 'fullName',
          label: 'Nombres y Apellidos del nuevo beneficiario:',
          type: 'onlyText',
          value: '',
          required: true,
          className: 'text-uppercase',
          readOnly: true,
        },
        {
          field: 'date',
          label: 'Fecha de nacimiento:',
          type: 'date',
          value: '',
          required: true,
          readOnly: true,
        },
        {
          field: 'parent',
          label: 'Vínculo:',
          type: 'select',
          value: '',
          required: true,
          options: listParent,
        },
        {
          field: 'percentage',
          label: '% de Capital Asegurado:',
          type: 'number',
          value: '',
          required: true,
          maxValue: 100,
          minValue: 1,
        },
        // { field: 'deleteField', label: 'Eliminar beneficiario', type: 'action', value: '', action: ()=>{console.log('add Delete')}, numberFields:7},
        {
          field: 'saveField',
          label: 'Guardar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 7,
          disabled: true,
        },
      ],
      INCB: [
        {
          field: 'cobrante',
          label: 'Nombres y Apellidos del nuevo beneficiario 2:',
          type: 'input',
          value: '',
          required: true,
          className: 'text-uppercase',
        },
        {
          field: 'tipoDocument',
          label: 'Tipo de documento:',
          type: 'select',
          value: '',
          required: true,
          options: [
            { label: 'Seleccione', value: '' },
            { label: 'DNI', value: 'dni' },
            { label: 'RUC', value: 'ruc' },
            { label: 'CE', value: 'ce' },
          ],
        },
        {
          field: 'nroDocument',
          label: 'Nº de documento:',
          type: 'number',
          value: '',
          required: true,
        },
        { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
        { field: 'vinculo', label: 'Vínculo:', type: 'input', value: '', required: true },
        {
          field: 'capitalA',
          label: '% de Capital Asegurado:',
          type: 'number',
          value: '',
          required: true,
          maxValue: 100,
        },
        {
          field: 'deleteField',
          label: 'Eliminar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 7,
        },
      ],

      EXCB: [
        {
          field: 'cobrante',
          label: 'Beneficiario a excluir:',
          type: 'input',
          value: '',
          required: true,
        },
        {
          field: 'tipoDocument',
          label: 'Tipo de documento:',
          type: 'select',
          value: '',
          required: true,
        },
        {
          field: 'nroDocument',
          label: 'Nº de documento:',
          type: 'number',
          value: '',
          required: true,
        },
        { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
        { field: 'vinculo', label: 'Vínculo:', type: 'input', value: '', required: true },
        {
          field: 'capitalA',
          label: '% de Capital Asegurado:',
          type: 'number',
          value: '',
          required: true,
          maxValue: 100,
        },
        {
          field: 'deleteField',
          label: 'Eliminar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 7,
        },
      ],

      CPC: [
        {
          field: 'beneficiario',
          label: 'Beneficiario 2:',
          type: 'input',
          value: '',
          required: true,
        },
        {
          field: 'capitalAsegurado',
          label: 'Ingrese el nuevo % del beneficiario 2:',
          type: 'number',
          value: '',
          required: true,
          maxValue: 100,
        },
        {
          field: 'deleteField',
          label: 'Eliminar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 3,
        },
      ],
    };

    const initialIndex = form[code].findIndex((item) => {
      return item.field === 'addField';
    });
    const { fieldAdd } = form[code][initialIndex];

    if (fieldAdd < 1 || (code === 'CPC' && fieldAdd < 2)) {
      form[code][initialIndex].fieldAdd = fieldAdd + 1;
      form[code][initialIndex].disabled = true;

      FieldsToAdd[code].forEach((item, index) => {
        let deleteFile = 'deleteField';
        let saveField = 'saveField';
        if (code !== 'ADB') {
          item.field += fieldAdd + 1;
          deleteFile += fieldAdd + 1;
          saveField += fieldAdd + 1;
        }

        if (item.field === deleteFile) {
          item.action = () => {
            deleteRecord(code, index + initialIndex);
          };
        }

        if (item.field === saveField) {
          item.action = () => {
            saveRecord(code, index + initialIndex, fieldAdd + 1);
          };
        }
        form[code].splice(initialIndex + index, 0, { ...item });
      });
      setForm({ ...form });
    }
  };
  // -----------managed of beneficiary--------------------------//
  const loadBeneficiary = async (policy) => {
    try {
      setLoadingBeneficiary(true);
      setMadeChanges(false);
      const response = await BeneficiaryRents.responseGetBeneficiary(policy, 'AT');
      if (response.data && response.data.data) {
        const beneficiary = response.data.data.map((item) => new Beneficiary(item));
        setBeneficiaryInfoInit(response.data.data.map((item) => new Beneficiary(item)));
        setBeneficiaryInfo({ ...beneficiaryInfo, listOfBeneficiary: beneficiary });
      }
      setLoadingBeneficiary(false);
    } catch (error) {
      setLoadingBeneficiary(false);
    }
  };

  const onChangeBeneficiary = (value, property, index) => {
    let isValid = true;
    if (property === 'percentage' && value > 100) {
      isValid = false;
    }
    if (isValid) {
      beneficiaryInfo.listOfBeneficiary[index][property] = value;
      setBeneficiaryInfo({ ...beneficiaryInfo });
    }
  };

  const saveRecord = (code = 'CGS', index) => {
    const findIndex = form.ADB.findIndex((item) => item.field === 'saveField');
    if (!form.ADB[findIndex].disabled) {
      // validar error en form
      const formInvalid = form[code].some((item) => item.error && item.error.trim() !== '');

      if (!formInvalid) {
        setIndexDelete(index);
        setSaveBeneficiary(true);
      }
    }
  };
  const onDeleteBeneficiary = (indexDelete) => {
    let listBeneficiary = { ...beneficiaryInfo }.listOfBeneficiary;
    listBeneficiary = listBeneficiary.filter((item, index) => index !== indexDelete);
    setBeneficiaryInfo({ ...beneficiaryInfo, listOfBeneficiary: listBeneficiary });
  };

  const onBlurModePage = async (index, code) => {
    const { value } = form[code][index];
    const { field } = form[code][index];
    const type = form[code][index - 1].value;

    if (value && code === 'ADB' && type && field === 'nroDocument') {
      const typeDocument = getValueDocumentWord(type.replaceAll('.', ''));

      // disabled btn saveField
      const initialIndex = form[code].findIndex((item) => {
        return item.field === 'saveField';
      });
      const stateSaveField = form[code][initialIndex].disabled;
      form[code][initialIndex].disabled = true;
      form[code][2].disabled = true;
      form[code][3].disabled = true;
      form[code][3].loading = true;
      setForm({ ...form });
      // end disabled btn save field
      const response = await BeneficiaryRents.responseGetInfoByDocument(
        typeDocument.toLowerCase() + value
      );

      if (response.status && response.status.id === 200) {
        const { data } = response;
        if (data) {
          form[code][initialIndex].disabled = stateSaveField; // enabled stateField

          // fullName
          form[code][4].value =
            data.length > 0
              ? `${data[0].name ? data[0].name : ''} ${data[0].lastName ? data[0].lastName : ''} ${
                  data[0].lastName2 ? data[0].lastName2 : ''
                }`
              : '';
          // date born
          form[code][5].value =
            data.length > 0 && data[0].birthdate
              ? data[0].birthdate.split('/').reverse().join('-')
              : '';

          currentNewBeneficiary[form[code][4].field] = form[code][4].value;
          currentNewBeneficiary[form[code][5].field] = form[code][5].value;
          form[code][3].loading = false;
          form[code][2].disabled = false;
          form[code][3].disabled = false;
          form[code][4].readOnly = true;
          form[code][5].readOnly = true;
          setForm({ ...form });
          setCurrentBeneficiary({ ...currentNewBeneficiary });
        }
      } else {
        form[code][2].disabled = false;
        form[code][3].disabled = false;
        form[code][3].info = 'No se pudo validar el documento';
        form[code][3].boldInfo = true;
        form[code][3].loading = false;
        form[code][4].readOnly = false;
        form[code][5].readOnly = false;
        form[code][4].value = '';
        form[code][5].value = '';

        currentNewBeneficiary[form[code][4].field] = '';
        currentNewBeneficiary[form[code][5].field] = '';
        setForm({ ...form });
        setCurrentBeneficiary({ ...currentNewBeneficiary });
      }
    } else {
      setForm({ ...form });
      setCurrentBeneficiary({ ...currentNewBeneficiary });
    }
  };

  let sumBeneficiaries = () => {
    const percentageAcc = beneficiaryInfo.listOfBeneficiary.reduce((acc, beneficiary) => {
      return parseFloat(beneficiary.percentage) + acc;
    }, 0);
    const initialIndex = form.ADB.findIndex((item) => {
      return item.field === 'addField';
    });
    if (isNaN(percentageAcc) || percentageAcc >= 100) {
      // add beneficiary disabled
      form.ADB[initialIndex].disabled = true;
      form.ADB[initialIndex].fieldAdd = 1;
    } else {
      form.ADB[initialIndex].disabled = false;
      form.ADB[initialIndex].fieldAdd = 0;
    }
    setForm({ ...form });
  };

  const getConstanciaUserNatural = async (policyCurrent) => {
    try {
      setLoadingOption(true);
      const { attributes: user, documentType, document: documentNumber } = userInfo;
      const dateFormatted = dateNowFormatted();
      const params = {
        data: {
          arrayBoletas: [
            {
              documentType,
              clientName: user?.legalName || '',
              documentNumber,
              initValidity: policyCurrent?.dateStart,
              finishValidity: policyCurrent?.dateEnd,
              policy: policyCurrent?.nroPolicy.toString().padStart(10, '0'),
              policyType: `Seguro de Ahorro Total`,
              ammount: 'Según condiciones de la Póliza',
              dateDescription: `Surquillo, ${dateFormatted}`,
            },
          ],
          enviarEmail: false,
        },
      };
      const headers = { 'segment-id': menuId };
      const { data, status } = await GenericConstancyServices.responseDocumentUserNatural(
        params,
        headers
      );

      if (data && status === 200) {
        const fixBase64 = fixStructBase64(data.document);
        setFileBase64.CDA = fixBase64;
      } else {
        setFileBase64.CDA = null;
      }
    } catch (error) {
      setFileBase64.CDA = null;
      dispatch(createErrorPage());
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };

  useEffect(() => {
    const valid = validateAllFields(Object.values(currentNewBeneficiary));
    const findIndex = form.ADB.findIndex((item) => item.field === 'saveField');
    if (valid) {
      if (findIndex !== -1) {
        form.ADB[findIndex].disabled = false;
        setForm({ ...form });
      }
    } else if (findIndex !== -1) {
      form.ADB[findIndex].disabled = true;
      setForm({ ...form });
    }
  }, [currentNewBeneficiary]);

  useEffect(() => {
    if (saveBeneficiary) {
      if (currentNewBeneficiary?.fullName)
        currentNewBeneficiary.fullName = capitalizeSentence(currentNewBeneficiary.fullName);

      const listNewBeneficiary = beneficiaryInfo.listOfBeneficiary;
      // Validate
      const [valid, forms] = validateFieldForm([...form.ADB], 'documents');
      const existBeneficiary = beneficiaryInfo.listOfBeneficiary.some((benef) => {
        return (
          benef.nroDocument === currentNewBeneficiary.nroDocument &&
          benef.typeDocument === currentNewBeneficiary.typeDocument
        );
      });
      // Add Beneficiary
      if (existBeneficiary) {
        form[currentCode][3].info = 'No se puede registrar 2 documentos iguales.';
        form[currentCode][3].boldInfo = true;
        // setForm({ ...form, [currentCode]: forms });
      } else if (valid) {
        setForm({ ...form, ADB: forms });
        listNewBeneficiary.push(currentNewBeneficiary);
        setBeneficiaryInfo({ ...beneficiaryInfo, listOfBeneficiary: listNewBeneficiary });
        setCurrentBeneficiary({ ...defaultBeneficiary });
        setTimeout(() => {
          deleteRecord(currentCode, indexDelete);
        }, [100]);
      }

      setSaveBeneficiary(false);
    }
  }, [saveBeneficiary]);

  useEffect(() => {
    sumBeneficiaries();
    const equalObjects =
      JSON.stringify(beneficiaryInfoInit) === JSON.stringify(beneficiaryInfo.listOfBeneficiary);
    setMadeChanges(!equalObjects);
  }, [beneficiaryInfo]);

  useEffect(() => {
    if (currentCode === 'ADB') {
      const fileIndex = form[currentCode].findIndex((item) => item.field === 'documents');
      const hasFile = typeof form[currentCode][fileIndex].value === 'object';
      setMadeChanges(!madeChanges ? hasFile : madeChanges);
    }
  }, [form]);
  // --------------------------------------------------------------------------------------//

  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];
      getOptionsAvailable(policyCurrent.rol);

      if (currentCode === 'ADB') {
        loadBeneficiary(policyCurrent.nroPolicy);
      }
      if (currentCode === 'CMP') {
        form.CMP[1].value = fullName;
        form.CMP[2].value = fullName;
      }
      if (currentCode === 'CDA') {
        getConstanciaUserNatural(policyCurrent);
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto Ahorro Total`,
      action: 'click',
      label: 'El usuario visitó la página de Ahorro Total',
      value: 1,
    });
  }, [ga]);

  useEffect(() => {
    if (currentIndex !== -1) {
      const currentData = policies[currentIndex];
      form.CMP[0].value = currentData.nroPolicy;
      form.CMP[1].value = fullName;
      form.CMP[2].value = fullName;

      form.CPC[0].value = currentData.nroPolicy;
      form.CPC[1].value = fullName;
      form.CPC[4].action = () => {
        addRegisto('CPC');
      };

      form.ADB[0].value = currentData.nroPolicy;
      form.ADB[1].value = fullName;
      form.ADB[2].action = () => {
        addRegisto('ADB');
      };

      setForm({ ...form });
    }
  }, [currentIndex]);

  return (
    <div className="home-ahorroTotal-container">
      {process.env.REACT_APP_NO_AVAILABLE_PRODUCTOS_AT === 'true' ? (
        <PSInfoProblems />
      ) : (
        <PSBodyProduct
          questions={FrequentQuestions.AHORRO_TOTAL}
          coverages={Coverage.AHORRO_TOTAL}
          linkToTicket={Routes.HOME_PRODUCT_AHORROTOTAL_TICKET}
          img={img}
          imgMobile={imgMobile}
          descriptionHeader="Ahorro Total Protecta"
          iconHeader="ahorroTotal"
          loading={loadingBeneficiary}
          data={policies}
          form={form}
          updateForm={(form) => setForm({ ...form })}
          options={getOptionsToProduct(options)}
          fileBase64={fileBase64}
          linkToSchedule={Routes.HOME_PRODUCT_AHORROTOTAL_SCHEDULE}
          headerTable={headerTableFather}
          onChangeModePage={onChangeModePage}
          onBlurModePage={onBlurModePage}
          onSelectItem={(item) => setCurrentIndex(item)}
          branchId={policies[currentIndex]?.nbranch || ''}
          productId={policies[currentIndex]?.idProduct || ''}
          menuId={menuId}
          menuDescription={menuDescription}
          clearForms={clearForms}
          useDigitalPolicy
          beneficiaryInfo={beneficiaryInfo}
          onSelectBeneficiary={onSelectBeneficiary}
          onChangeBeneficiary={onChangeBeneficiary}
          onDeleteBeneficiary={onDeleteBeneficiary}
          onSetCurrentCodeForm={setCurrentCode}
          madeChanges={madeChanges}
          loadingOption={loadingOption}
          policiesMessage={policiesMessage}
          {...props}
        />
      )}
    </div>
  );
}
