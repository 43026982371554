import React from 'react';
import styled from 'styled-components';

const StyledPSFooterPageIntranet = styled.div`
  width: 100%;
  background: #262d33;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: BlissPro-Bold;
  .description-footer {
    padding: 0.5rem 0.5rem;
  }
`;

const PSFooterPageIntranet = React.forwardRef(() => {
  return (
    <StyledPSFooterPageIntranet>
      <p className="description-footer">Copyright © Protecta Security 2020</p>
    </StyledPSFooterPageIntranet>
  );
});

export default PSFooterPageIntranet;
