import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cloneDeep from 'loadsh/cloneDeep';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { createErrorPage } from '../../redux/auth';

import {
  getMaxLenghCuentasByType,
  getMaxLengthDocument,
  getOptionsToProduct,
  getValueDocumentWord,
  validateAllFields,
  validateFieldForm,
  capitalizeSentence,
  dateNowFormatted,
  fixStructBase64,
  getProductInfo,
} from '../../../util/Util';
import { menuCode, policyFilterMessage } from '../../shared/constants/Strings';
import { Coverage } from '../../shared/constants/Coverage';
import { FrequentQuestions } from '../../shared/constants/FrequentQuestions';
import { formAdb, formCmp, formCpc, formMgs, listParent } from '../../shared/constants/Forms';
import BeneficiaryRents from '../../shared/services/BeneficiaryRents';
import GenericConstancyServices from '../../shared/services/GenericConstancyServices';

import PSBodyProduct from '../../components/PSBodyProduct';
import { Routes } from '../../../routes';
import { GAProvider } from '../../components/PSAnalytics';
import { PSInfoProblems } from '../../components/molecules';
import { OTHER_BANKS, userDefault } from '../../../util/Constant';
import { Beneficiary } from '../../shared/model/Beneficiary';

import './index.scss';

const img = 'product-rentaTotal';
const imgMobile = 'product-rentaTotal-mobile';

const headerTableFather = [
  { elementProp: 'shortDescription', headerColumn: 'Producto' },
  { elementProp: 'nroPolicy', headerColumn: 'Póliza' },
  { elementProp: 'prima', headerColumn: 'Prima' },
  { elementProp: 'nroCertificate', headerColumn: 'Número de certificado' },
  { elementProp: 'rol', headerColumn: 'Rol' },
  { elementProp: 'dateVigencia', headerColumn: 'Vigencia' },
];

const defaultBeneficiary = {
  typeDocument: '',
  nroDocument: null,
  fullName: '',
  parent: '',
  percentage: null,
  date: null,
};

export default function HomeServices(props) {
  const [paramsComprobantes, setParamsComprobantes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const [loadingBeneficiary, setLoadingBeneficiary] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const attributes = userInfo && userInfo.attributes ? userInfo.attributes : userDefault;
  const fullName = `${attributes?.middleName} ${attributes?.familyName} ${attributes?.givenName}`;
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const ga = React.useContext(GAProvider);
  const [options, setOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [fileBase64, setFileBase64] = useState({});
  const location = useLocation();
  const {
    product: menuId,
    productDescription: menuDescription,
    policies
  } = getProductInfo(userInfo?.productsDetail, menuCode.RENTA_TOTAL);
  const [currentCode, setCurrentCode] = useState(null);
  const [indexDelete, setIndexDelete] = useState(-1);
  const [beneficiaryInfo, setBeneficiaryInfo] = useState({
    listOfBeneficiary: [],
    currentBeneficiary: -1,
  });
  const [beneficiaryInfoInit, setBeneficiaryInfoInit] = useState([]);
  const [madeChanges, setMadeChanges] = useState(false); // form ADB
  const [beneficiaryInfoBurial, setBeneficiaryInfoBurial] = useState({
    listOfBeneficiary: [],
    currentBeneficiary: -1,
  });
  const dispatch = useDispatch();
  const [currentNewBeneficiary, setCurrentBeneficiary] = useState({ ...defaultBeneficiary });
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);

  const [form, setForm] = useState({
    CMP: cloneDeep(formCmp),
    ADB: cloneDeep(formAdb), // Actualización beneficiario
    CPC: cloneDeep(formCpc),
    CGS: cloneDeep(formMgs), // Modificación de beneficiarios sepelio
  });

  const policiesMessage = policyFilterMessage.rentaTotal;

  const getOptionsAvailable = (rol = 'Asegurado') => {
    const options = [];
    if (process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_LIQUIDACION_PAGO === 'true') {
      options.push('LDP');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_CRONOGRAMA_PAGO === 'true') {
      options.push('CP');
    }

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_CUADRO_VALORES_GARANTIZADOS === 'true'
    ) {
      options.push('CDG');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_DESCARGA_POLIZA === 'true') {
      options.push('DP');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_CAMBIO_MODALIDAD_PAGO === 'true') {
      options.push('CMP');
    }

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_ACTUALIZACION_BENEFICIARIOS === 'true'
    ) {
      options.push('ADB'); // Modificación de benenficiaarion
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_PORCENTAJE === 'true') {
      options.push('CPC');
    }

    if (process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_GASTOS_SEPELIO === 'true') {
      options.push('CGS');
    }

    if (
      process.env.REACT_APP_PERMISSION_PRODUCT_RENTA_TOTAL_CONSTANCIA_ASEGURADO === 'true' &&
      rol.trim() !== 'Contratante'
    ) {
      options.push('CDA');
    }
    setOptions(options);
  };

  const onSelectBeneficiary = (index) => {
    beneficiaryInfo.currentBeneficiary = index;
    setBeneficiaryInfo({ ...beneficiaryInfo });
  };

  const onSelectBeneficiaryBurial = (index) => {
    beneficiaryInfoBurial.currentBeneficiary = index;
    setBeneficiaryInfoBurial({ ...beneficiaryInfoBurial });
  };

  const clearForms = (code) => {
    const listBeneficiaryB = beneficiaryInfoBurial.listOfBeneficiary.length >= 1;
    if (code) {
      form[code].forEach((item) => {
        if (!item.readOnly) {
          item.value = '';
          item.error = '';
          if (item.field === 'addField') {
            item.fieldAdd = 0;
            item.disabled = false;
          }
        }
      });

      setForm({ ...form });
    }
    const lasFieldDefaultIndex = form[code].findIndex((item) => {
      return item.lastFieldDefault;
    });

    if (lasFieldDefaultIndex !== -1 && form[code].length > lasFieldDefaultIndex + 4) {
      const numberOfFields = form[code][lasFieldDefaultIndex].numberFields;
      form[code].splice(lasFieldDefaultIndex + 1, numberOfFields);
      setForm({ ...form });
    }
    setTimeout(() => {
      setCurrentCode(null);
    }, 1000);
  };

  const onchangeModePage = (e, index, code) => {
    let value = e.target ? e.target.value : e;
    const { maxLength } = form[code][index];
    const { maxValue } = form[code][index];
    const { minValue } = form[code][index];

    let isValid = true;
    if (maxLength) {
      if (value.length > maxLength) {
        isValid = false;
      }
    }

    if (maxValue) {
      if (value > maxValue) {
        isValid = false;
      }
    }

    if (isValid) {
      form[code][index].value = value;

      if (form[code][index].field === 'banco') {
        let indexUpdate = 7;

        if (value !== 'otros') {
          form[code] = form[code].filter((item) => {
            return item.field !== 'otroBanco';
          });
          // Eliminar CCI
          form[code][8].onlySelect = true;
          form[code][8].required = false;
          // form[code][8].required = true; // show CCI
        } else {
          form[code].splice(5, 0, {
            field: 'otroBanco',
            label: 'Nombre del banco:',
            type: 'select',
            value: '',
            required: true,
            options: [...OTHER_BANKS],
          });
          indexUpdate += 1;
          form[code][indexUpdate + 1].required = true;
          form[code][indexUpdate + 1].onlySelect = false; // hidden CCI
        }
        form[code][indexUpdate + 1].value = '';
      }

      if (
        form[code][index].field === 'otroBanco' ||
        form[code][index].field === 'banco' ||
        form[code][index].field === 'tipoDeCuenta'
      ) {
        const indexAccount = form[code].findIndex((item) => item.field === 'account');
        const bankG = form[code].find((item) => item.field === 'banco')?.value;
        const typeAccount = form[code].find((item) => item.field === 'tipoDeCuenta')?.value;
        const bank =
          bankG === 'otros' ? form[code].find((item) => item.field === 'otroBanco')?.value : bankG;

        form[code][indexAccount].value = '';
        form[code][indexAccount].error = '';
        form[code][indexAccount].maxLength = getMaxLenghCuentasByType(bank, typeAccount); // todo replace dynamic
      }

      if (
        form[code][index].field === 'tipoDocument' ||
        form[code][index].field === 'typeDocument' ||
        form[code][index].field.includes('tipoDocument')
      ) {
        form[code][index + 1].value = '';
        form[code][index + 1].maxLength = getMaxLengthDocument(value);
      }

      if (form[code][index].field === 'nroDocument') {
        form[code][index + 1].value = '';
        form[code][index + 2].value = '';
        form[code][3].info = '';
        form[code][3].boldInfo = false;
        form[code][3].error = value.length === maxLength ? '' : form[code][3].error;
      }

      if (form[code][index].field === 'percentage') {
        value = parseFloat(value);
        form[code][index].value = value.toString();

        const indexSave = form[code].findIndex((item) => item.field === 'saveField');
        if (minValue && value < minValue) {
          form[code][index].error = `Ingrese un valor mayor a ${minValue - 1}`;
          form[code][indexSave].disabled = true;
        } else {
          form[code][index].error = '';
        }
      }
      setForm({ ...form });

      if ((code === 'ADB' || code === 'CGS') && !form[code][index].readOnly) {
        currentNewBeneficiary[form[code][index].field] = value;
        setCurrentBeneficiary({ ...currentNewBeneficiary });
      }
    }
  };

  const deleteRecord = (code = 'CGS', index) => {
    const field = form[code][index];
    const numbersDelete = field.numberFields;
    form[code].splice(index - numbersDelete + 1, numbersDelete);
    const initialIndex = form[code].findIndex((item) => item.field === 'addField');

    if (code.trim() === 'CGS') {
      form[code][initialIndex].disabled = true;
      form[code][initialIndex].fieldAdd = 1;
    } else {
      // otros formularios
      form[code][initialIndex].fieldAdd = 0;
      form[code][initialIndex].disabled = false;
    }

    if (code.trim() === 'ADB') {
      sumBeneficiaries();
    }
    setForm({ ...form });
  };

  const addRegisto = (code = 'CGS') => {
    const FieldsToAdd = {
      ADB: [
        {
          field: 'typeDocument',
          label: 'Tipo de documento:',
          type: 'select',
          value: '',
          required: true,
          options: [
            { label: 'Seleccione', value: '' },
            { label: 'DNI', value: 'D.N.I' },
          ],
        },
        {
          field: 'nroDocument',
          label: 'Nº de documento:',
          type: 'number',
          value: '',
          required: true,
          loading: false,
        },
        {
          field: 'fullName',
          label: 'Nombres y Apellidos del nuevo beneficiario:',
          className: 'text-uppercase',
          type: 'onlyText',
          value: '',
          required: true,
          readOnly: true,
        },
        {
          field: 'date',
          label: 'Fecha de nacimiento:',
          type: 'date',
          value: '',
          required: true,
          readOnly: true,
        },
        {
          field: 'parent',
          label: 'Vínculo:',
          type: 'select',
          value: '',
          required: true,
          options: listParent,
        },
        {
          field: 'percentage',
          label: '% de Capital Asegurado:',
          type: 'number',
          value: '',
          required: true,
          maxValue: 100,
          minValue: 1,
        },
        {
          field: 'saveField',
          label: 'Guardar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 7,
          disabled: true,
        },
      ],

      CGS: [
        {
          field: 'typeDocument',
          label: 'Tipo de documento:',
          type: 'select',
          value: '',
          required: true,
          options: [
            { label: 'Seleccione', value: '' },
            { label: 'DNI', value: 'D.N.I' },
          ],
        },
        {
          field: 'nroDocument',
          label: 'Nº de documento:',
          type: 'number',
          value: '',
          required: true,
        },
        {
          field: 'fullName',
          label: 'Nombres y Apellidos del nuevo beneficiario',
          className: 'text-uppercase',
          type: 'onlyText',
          value: '',
          required: true,
        },
        { field: 'date', label: 'Fecha de nacimiento:', type: 'date', value: '', required: true },
        {
          field: 'parent',
          label: 'Vínculo:',
          type: 'select',
          value: '',
          options: listParent,
          required: true,
        },
        {
          field: 'saveField',
          label: 'Guardar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 6,
          disabled: true,
        },
      ],
      CPC: [
        {
          field: 'beneficiario',
          label: 'Beneficiario 2:',
          type: 'input',
          value: '',
          required: true,
        },
        {
          field: 'capitalAsegurado',
          label: 'Ingrese el nuevo % del beneficiario 2:',
          type: 'number',
          value: '',
          required: true,
          maxValue: 100,
        },
        {
          field: 'deleteField',
          label: 'Eliminar beneficiario',
          type: 'action',
          value: '',
          action: () => {
            console.log('add Delete');
          },
          numberFields: 3,
        },
      ],
    };

    const initialIndex = form[code].findIndex((item) => {
      return item.field === 'addField';
    });
    const { fieldAdd } = form[code][initialIndex];

    if (
      (fieldAdd < 1 || ((code === 'CPC' || code === 'CGS') && fieldAdd < 2)) &&
      !form[code][initialIndex].disabled
    ) {
      form[code][initialIndex].fieldAdd = 1; // fieldAdd + 1;
      form[code][initialIndex].disabled = true;
      FieldsToAdd[code].forEach((item, index) => {
        let deleteFile = 'deleteField';
        let saveField = 'saveField';
        if (code !== 'ADB' && code !== 'CGS') {
          //
          //   CMP: cloneDeep(formCmp),
          //   ADB: cloneDeep(formAdb), // Actualización beneficiario
          //   CPC: cloneDeep(formCpc),
          //   CGS:
          // [
          //   'INVALID_USER',
          //   'ERROR_USER',
          //   'NO_DATA_USER',
          //   'USER_INVALID_POLICIES',
          //   'ERROR_TIME',
          //   'USER_INVALID_ACCESS',
          // ].includes(code)

          item.field += fieldAdd + 1;
          deleteFile += fieldAdd + 1;
          saveField += fieldAdd + 1;
        }

        if (item.field === deleteFile) {
          item.action = () => {
            deleteRecord(code, index + initialIndex);
          };
        }

        if (item.field === saveField) {
          item.action = () => {
            saveRecord(code, index + initialIndex, fieldAdd + 1);
          };
        }
        form[code].splice(initialIndex + index, 0, { ...item });
      });
      setForm({ ...form });
    }
  };

  // -----------managed of beneficiary--------------------------//
  const loadBeneficiary = async (policy) => {
    try {
      setLoadingBeneficiary(true);
      setMadeChanges(false);
      const response = await BeneficiaryRents.responseGetBeneficiary(policy, 'RT');
      if (response.data && response.data.data) {
        const beneficiary = response.data.data.map((item) => new Beneficiary(item));
        setBeneficiaryInfoInit(response.data.data.map((item) => new Beneficiary(item)));
        setBeneficiaryInfo({ ...beneficiaryInfo, listOfBeneficiary: beneficiary });
      }
      setLoadingBeneficiary(false);
    } catch (error) {
      setLoadingBeneficiary(false);
    }
  };

  const onChangeBeneficiary = (value, property, index) => {
    let isValid = true;
    if (property === 'percentage' && value > 100) {
      isValid = false;
    }
    if (isValid) {
      beneficiaryInfo.listOfBeneficiary[index][property] = value;
      setBeneficiaryInfo({ ...beneficiaryInfo });
    }
  };

  const saveRecord = (code = 'CGS', index) => {
    setIndexDelete(index);
    const findIndex = form[code].findIndex((item) => item.field === 'saveField');
    if (!form[code][findIndex].disabled) {
      // validar error en form
      const formInvalid = form[code].some((item) => item.error && item.error.trim() !== '');
      if (!formInvalid) {
        setIndexDelete(index);
        setSaveBeneficiary(true);
      }
    }
  };

  const onDeleteBeneficiary = (indexDelete) => {
    let listBeneficiary = { ...beneficiaryInfo }.listOfBeneficiary;
    listBeneficiary = listBeneficiary.filter((item, index) => index !== indexDelete);
    setBeneficiaryInfo({ ...beneficiaryInfo, listOfBeneficiary: listBeneficiary });
  };

  const loadBeneficiaryBurial = async (policy) => {
    try {
      setLoadingBeneficiary(true);
      const response = await BeneficiaryRents.responseGetBeneficiary(
        policy,
        'RT',
        'funeral-expenses'
      );
      if (response.data && response.data.data) {
        const beneficiaryB = response.data.data.map((item) => new Beneficiary(item));

        const initialIndex = form.CGS.findIndex((item) => {
          return item.field === 'addField';
        });

        const isDisabled = beneficiaryB.length > 0;
        if (isDisabled) {
          form.CGS[initialIndex].disabled = isDisabled;
          form.CGS[initialIndex].addField = 1;
          setForm({ ...form });
        }
        setBeneficiaryInfoBurial({ ...beneficiaryInfoBurial, listOfBeneficiary: beneficiaryB });
      }

      setLoadingBeneficiary(false);
    } catch (error) {
      setLoadingBeneficiary(false);
    }
  };

  const onDeleteBeneficiaryBurial = (indexDelete) => {
    let listBeneficiaryB = { ...beneficiaryInfoBurial }.listOfBeneficiary;
    listBeneficiaryB = listBeneficiaryB.filter((item, index) => index !== indexDelete);
    beneficiaryInfoBurial.currentBeneficiary = -1;
    beneficiaryInfoBurial.listOfBeneficiary = listBeneficiaryB;
    setBeneficiaryInfoBurial({ ...beneficiaryInfoBurial });
    // Volver a habilitar el fileAdd
    const initialIndex = form.CGS.findIndex((item) => {
      return item.field === 'addField';
    });

    form.CGS[initialIndex].fieldAdd = 0;
    form.CGS[initialIndex].disabled = false;
    setForm({ ...form });
  };

  // ------------------------------------------------------//

  const onBlurModePage = async (index, code) => {
    let type;
    const { value } = form[code][index];
    const { field } = form[code][index];

    type = index > 0 && form[code][index - 1].value;

    if (value && (code === 'ADB' || code === 'CGS') && type && field === 'nroDocument') {
      const typeDocument = getValueDocumentWord(type.replaceAll('.', ''));

      // disabled btn saveField
      const initialIndex = form[code].findIndex((item) => {
        return item.field === 'saveField';
      });
      form[code][initialIndex].disabled = true;
      form[code][2].disabled = true;
      form[code][3].disabled = true;
      form[code][3].loading = true;
      setForm({ ...form });
      // end disabled btn save field

      const response = await BeneficiaryRents.responseGetInfoByDocument(
        typeDocument.toLowerCase() + value
      );

      if (response.status && response.status.id === 200) {
        const { data } = response;
        if (data) {
          // fullname
          form[code][4].value =
            data.length > 0
              ? `${data[0].name ? data[0].name : ''} ${data[0].lastName ? data[0].lastName : ''} ${
                  data[0].lastName2 ? data[0].lastName2 : ''
                }`
              : '';
          // date
          form[code][5].value =
            data.length > 0 && data[0].birthdate
              ? data[0].birthdate.split('/').reverse().join('-')
              : '';

          currentNewBeneficiary[form[code][4].field] =
            data.length > 0 ? `${data[0].name} ${data[0].lastName} ${data[0].lastName2}` : '';
          currentNewBeneficiary[form[code][5].field] =
            data.length > 0 && data[0].birthdate
              ? data[0].birthdate.split('/').reverse().join('-')
              : '';

          form[code][3].loading = false;
          form[code][2].disabled = false;
          form[code][3].disabled = false;
          form[code][4].readOnly = true;
          form[code][5].readOnly = true;
          setForm({ ...form });
          setCurrentBeneficiary({ ...currentNewBeneficiary });
        }
      } else {
        form[code][2].disabled = false;
        form[code][3].disabled = false;
        form[code][3].info = 'No se pudo validar el documento';
        form[code][3].boldInfo = true;
        form[code][3].loading = false;
        form[code][4].readOnly = false;
        form[code][5].readOnly = false;
        form[code][4].value = '';
        form[code][5].value = '';

        currentNewBeneficiary[form[code][4].field] = '';
        currentNewBeneficiary[form[code][5].field] = '';
        setForm({ ...form });
        setCurrentBeneficiary({ ...currentNewBeneficiary });
      }
    } else {
      setForm({ ...form });
      setCurrentBeneficiary({ ...currentNewBeneficiary });
    }
  };

  let sumBeneficiaries = () => {
    const percentageAcc = beneficiaryInfo.listOfBeneficiary.reduce((acc, beneficiary) => {
      return parseFloat(beneficiary.percentage) + acc;
    }, 0);
    const initialIndex = form.ADB.findIndex((item) => {
      return item.field === 'addField';
    });
    if (isNaN(percentageAcc) || percentageAcc >= 100) {
      // add beneficiary disabled
      form.ADB[initialIndex].disabled = true;
      form.ADB[initialIndex].fieldAdd = 1;
    } else {
      form.ADB[initialIndex].disabled = false;
      form.ADB[initialIndex].fieldAdd = 0;
    }
    setForm({ ...form });
  };

  const getConstanciaUserNatural = async (policyCurrent) => {
    try {
      setLoadingOption(true);
      const { attributes: user, documentType, document: documentNumber } = userInfo;
      const dateFormatted = dateNowFormatted();
      const params = {
        data: {
          arrayBoletas: [
            {
              documentType,
              clientName: user?.legalName || '',
              documentNumber,
              initValidity: policyCurrent?.dateStart,
              finishValidity: policyCurrent?.dateEnd,
              policy: policyCurrent?.nroPolicy.toString().padStart(10, '0'),
              policyType: `Seguro de Renta Total`,
              ammount: 'Según condiciones de la Póliza',
              dateDescription: `Surquillo, ${dateFormatted}`,
            },
          ],
          enviarEmail: false,
        },
      };
      const headers = { 'segment-id': menuId };
      const { data, status } = await GenericConstancyServices.responseDocumentUserNatural(
        params,
        headers
      );

      if (data && status === 200) {
        const fixBase64 = fixStructBase64(data.document);
        setFileBase64.CDA = fixBase64;
      } else {
        setFileBase64.CDA = null;
      }
    } catch (error) {
      setFileBase64.CDA = null;
      dispatch(createErrorPage());
    } finally {
      setFileBase64({ ...setFileBase64 });
      setLoadingOption(false);
    }
  };

  useEffect(() => {
    if (currentCode) {
      const index = currentCode === 'CGS' ? 4 : -1;
      const valid = validateAllFields(Object.values(currentNewBeneficiary), null, index);
      const findIndex = form[currentCode].findIndex((item) => item.field === 'saveField');
      if (valid) {
        if (findIndex !== -1) {
          form[currentCode][findIndex].disabled = false;
          setForm({ ...form });
        }
      } else if (findIndex !== -1) {
        form[currentCode][findIndex].disabled = true;
        setForm({ ...form });
      }
    }
  }, [currentNewBeneficiary]);

  useEffect(() => {
    if (saveBeneficiary) {
      const [valid, forms] = validateFieldForm([...form[currentCode]], 'documents');
      if (currentNewBeneficiary?.fullName)
        currentNewBeneficiary.fullName = capitalizeSentence(currentNewBeneficiary.fullName);

      if (currentCode === 'ADB') {
        const listNewBeneficiary = beneficiaryInfo.listOfBeneficiary;

        // Validate
        const existBeneficiary = beneficiaryInfo.listOfBeneficiary.some((benef) => {
          return (
            benef.nroDocument === currentNewBeneficiary.nroDocument &&
            benef.typeDocument === currentNewBeneficiary.typeDocument
          );
        });

        // Add Beneficiary
        if (existBeneficiary) {
          form[currentCode][3].info = 'No se puede registrar 2 documentos iguales.';
          form[currentCode][3].boldInfo = true;
          form[currentCode][8].disabled = false;
          // setForm({ ...form, [currentCode]: forms });
        } else if (valid) {
          setForm({ ...form, [currentCode]: forms });
          listNewBeneficiary.push(currentNewBeneficiary);
          setBeneficiaryInfo({ ...beneficiaryInfo, listOfBeneficiary: listNewBeneficiary });
          setCurrentBeneficiary({ ...defaultBeneficiary });
          setTimeout(() => {
            deleteRecord(currentCode, indexDelete);
          }, [100]);
        }
      } else if (valid) {
        // CGS sepelio

        const listNewBeneficiaryS = beneficiaryInfoBurial.listOfBeneficiary;
        setForm({ ...form, [currentCode]: forms });
        listNewBeneficiaryS.push(currentNewBeneficiary);
        setBeneficiaryInfoBurial({
          ...beneficiaryInfoBurial,
          listOfBeneficiary: listNewBeneficiaryS,
        });
        setCurrentBeneficiary({ ...defaultBeneficiary });
        setTimeout(() => {
          deleteRecord(currentCode, indexDelete);
        }, [100]);
      }
      // setCurrentBeneficiary({ ...defaultBeneficiary });

      setSaveBeneficiary(false);
    }
  }, [saveBeneficiary]);

  useEffect(() => {
    sumBeneficiaries();
    const equalObjects =
      JSON.stringify(beneficiaryInfoInit) === JSON.stringify(beneficiaryInfo.listOfBeneficiary);
    setMadeChanges(!equalObjects);
  }, [beneficiaryInfo]);

  useEffect(() => {
    if (currentCode === 'ADB') {
      const fileIndex = form[currentCode].findIndex((item) => item.field === 'documents');
      const hasFile = typeof form[currentCode][fileIndex].value === 'object';
      setMadeChanges(!madeChanges ? hasFile : madeChanges);
    }
  }, [form]);

  // --------------------------------------------------------------------------------------//

  useEffect(() => {
    if (currentIndex !== -1) {
      const policyCurrent = policies[currentIndex];
      getOptionsAvailable(policyCurrent.rol);

      if (currentCode === 'ADB') {
        loadBeneficiary(policyCurrent.nroPolicy);
      }
      if (currentCode === 'CGS') {
        loadBeneficiaryBurial(policyCurrent.nroPolicy);
      }
      if (currentCode === 'CDA') {
        getConstanciaUserNatural(policyCurrent);
      }
    }
  }, [currentIndex, currentCode]);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Producto Renta Total`,
      action: 'click',
      label: 'El usuario visitó la página de Renta Total',
      value: 1,
    });
  }, [ga]);

  useEffect(() => {
    if (currentIndex !== -1) {
      const currentData = policies[currentIndex];
      form.CMP[0].value = currentData.nroPolicy;
      form.CMP[1].value = fullName;

      form.CPC[0].value = currentData.nroPolicy;
      form.CPC[1].value = fullName;
      form.CPC[4].action = () => {
        addRegisto('CPC');
      };

      form.ADB[0].value = currentData.nroPolicy;
      form.ADB[1].value = fullName;
      form.ADB[2].action = () => {
        addRegisto('ADB');
      };

      // CGS
      form.CGS[0].value = currentData.nroPolicy;
      form.CGS[1].value = fullName;
      form.CGS[2].action = () => {
        addRegisto('CGS');
      };

      setForm({ ...form });
    }
  }, [currentIndex]);

  return (
    <div className="home-rentaTotal-container w-100 h-100">
      {process.env.REACT_APP_NO_AVAILABLE_PRODUCTOS === 'true' ? (
        <PSInfoProblems />
      ) : (
        <PSBodyProduct
          questions={FrequentQuestions.RENTA_TOTAL}
          coverages={Coverage.RENTA_TOTAL}
          paramsComprobantes={paramsComprobantes}
          linkToTicket={Routes.HOME_PRODUCT_RENTATOTAL_TICKET}
          img={img}
          imgMobile={imgMobile}
          options={getOptionsToProduct(options)}
          fileBase64={fileBase64}
          form={form}
          updateForm={(form) => setForm({ ...form })}
          linkToSchedule={Routes.HOME_PRODUCT_RENTATOTAL_SCHEDULE}
          descriptionHeader="Renta Total Protecta"
          iconHeader="renta"
          loading={loading || loadingBeneficiary}
          data={policies}
          headerTable={headerTableFather}
          onChangeModePage={onchangeModePage}
          onBlurModePage={onBlurModePage}
          onSelectItem={(item) => setCurrentIndex(item)}
          clearForms={clearForms}
          branchId={policies[currentIndex]?.nbranch || ''}
          productId={policies[currentIndex]?.idProduct || ''}
          menuId={menuId}
          menuDescription={menuDescription}
          useDigitalPolicy
          beneficiaryInfo={beneficiaryInfo}
          beneficiaryInfoBurial={beneficiaryInfoBurial}
          onSelectBeneficiary={onSelectBeneficiary}
          onChangeBeneficiary={onChangeBeneficiary}
          onDeleteBeneficiary={onDeleteBeneficiary}
          onSelectBeneficiaryBurial={onSelectBeneficiaryBurial}
          onDeleteBeneficiaryBurial={onDeleteBeneficiaryBurial}
          onSetCurrentCodeForm={setCurrentCode}
          madeChanges={madeChanges}
          loadingOption={loadingOption}
          policiesMessage={policiesMessage}
          {...props}
        />
      )}
    </div>
  );
}
