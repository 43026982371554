import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'loadsh/isEmpty';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { setLoginUser, validateLoginUser } from '../../redux/auth';
import { Routes } from '../../../routes';
import { Strings } from '../../shared/constants/Strings';
import { GAProvider } from '../../components/PSAnalytics';
import { NEED_UPDATE_PASSWORD, STORAGE_TOKEN_COGNITO, typeDocument } from '../../../util/Constant';

import PSLoginContainer from '../../components/PSLoginContainer';
import PSTextInput from '../../components/PSTextInput';
import PSButton from '../../components/PSButton';
import PSBox from '../../components/PSBox';
import PSLabel from '../../components/PSLabel';
import PSSelect from '../../components/PSSelect';
import PSError from '../../components/PSError';
import PSLanding from '../../components/PSLanding';
import { decodeb64, getDocumentInfo, maxLengthCheck } from '../../../util/Util';

import './index.scss';
import 'react-responsive-modal/styles.css';

const DIRECT_FLOW_INITIAL = {
  typeDocument: '',
  numberDocument: '',
  policy: '',
  validate: false,
  key: null,
};
const FORMIK_VALUES_INITIAL = {
  documentType: '',
  date: '',
  documentId: '',
  password: '',
};

export default function Login({ location }) {
  const { pathname, search } = location;
  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfo, loading } = useSelector((state) => state.auth);
  const { kushkiAtributes } = userInfo;
  const [tryLogIn, setTryLogIn] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [dataUrl, setDataUrl] = useState(DIRECT_FLOW_INITIAL);
  const ga = React.useContext(GAProvider);
  const formik = useFormik({
    initialValues: FORMIK_VALUES_INITIAL,
    validationSchema: Yup.object().shape({
      documentId: Yup.string()
        .min(8, 'Necesita mínimo 8 caracteres')
        .required('Documento es requerido')
        .when('documentType', {
          is: 'L',
          then: Yup.string().max(8, 'El documento solo tiene 8 caracteres'),
        }),
      password: Yup.string()
        .min(8, 'Necesita mínimo 8 caracteres')
        .required('La contraseña es requerida'),
      documentType: Yup.string().required('Seleccione el tipo de documento'),
    }),
    onSubmit(form) {
      setTryLogIn(true);
      const { documentId, password, documentType } = form;
      dispatch(
        validateLoginUser(
          documentId.toString(),
          password.toString(),
          documentType,
          dataUrl.policy,
          dataUrl.key
        )
      );
    },
  });

  const needLoading = () => {
    if (pathname.includes(Routes.LOGIN) || pathname === Routes.BASE) {
      return loading;
    }
    return false;
  };

  const getId = () => {
    try {
      const data = decodeb64(id);
      if (data?.length === 3) {
        const [key, document, policy] = data;
        const { documentNumber, documentLetter = 'L' } = getDocumentInfo(document);
        setDataUrl({
          ...dataUrl,
          typeDocument: documentLetter?.toUpperCase(),
          numberDocument: documentNumber,
          policy,
          key,
          validate: true,
        });
      }
    } catch (error) {
      console.log('error id', error);
    }
  };

  useEffect(() => {
    const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );

    ga.pageview(pathname + search);
    const device = isMobile ? 'el celular' : isTablet ? 'la tablet' : 'desktop';
    ga.event({
      category: 'Net Privada - Public zone',
      action: 'visit',
      label: `El usuario visitó el Landing desde ${device}`,
      value: 1,
    });
  }, [ga]);

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      if (userInfo.valid) {
        const needUpdatePassword = localStorage.getItem(NEED_UPDATE_PASSWORD);
        if (
          userInfo.attributes?.firstLogin &&
          (userInfo.documentType === 'R' || userInfo.attributes?.appOrigin === 'NPBACKOF') &&
          needUpdatePassword !== '0'
        ) {
          localStorage.setItem(NEED_UPDATE_PASSWORD, '1');
          history.push(Routes.SIGN_UP_CREATE_PASSWORD);
        } else {
          localStorage.setItem(NEED_UPDATE_PASSWORD, '0');
          // Validate if the user redirected to vdp kushki
          if (kushkiAtributes?.validateOTP && kushkiAtributes?.useChangeCard) {
            history.push(Routes.HOME_PRODUCT_LIFE_DEVOLUTION);
          } else {
            history.push(Routes.HOME);
            const messageTypeUser = userInfo.documentType === 'R' ? 'EMPRESA-' : 'PERSONA-';
            ga.pageview(pathname + search);
            ga.event({
              category: `${messageTypeUser}HOME`,
              action: 'login',
              label: 'El usuario se pudo loguear en la Net Privada y visitó el Home',
              value: 1,
            });
          }
        }
      } else if (userInfo.codeError === 'NotAuthorizedException') {
        setErrorLogin(
          userInfo.message?.includes('disabled')
            ? Strings.ERROR_USER_BLOCKED
            : Strings.ERROR_MESSAGE_LOGIN
        );
        setTimeout(() => {
          dispatch(setLoginUser({}));
        }, 5000);
      }
    } else {
      setErrorLogin(null);
    }
  }, [userInfo]);

  useEffect(() => {
    if (dataUrl?.validate) {
      formik.setValues({
        documentType: dataUrl.typeDocument,
        documentId: dataUrl.numberDocument,
      });
    }
  }, [dataUrl]);

  useEffect(() => {
    if (id) getId();
  }, [id]);

  return (
    <PSLanding showText={false}>
      <div className="login-register-container">
        <PSBox className="login-body-container">
          <PSLoginContainer className="login-container" showCloseIcon={false}>
            <PSLabel className="login-header-title">¡Bienvenido!</PSLabel>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="login-box-login">
                <PSSelect
                  className={
                    formik.touched.documentType && formik.errors.documentType ? 'with-error' : ''
                  }
                  {...formik.getFieldProps('documentType')}
                  options={[{ value: '', label: 'Seleccione documento' }, ...typeDocument]}
                  selectedValue={
                    dataUrl.validate ? dataUrl.typeDocument : formik.values.documentType
                  }
                  disabled={dataUrl.validate}
                />
                <PSError>{formik.touched.documentType && formik.errors.documentType}</PSError>
                <PSTextInput
                  maxLength={formik.values.documentType === 'L' ? 8 : 11}
                  onKeyPress={(e) =>
                    formik.values.documentType !== 'G' ? maxLengthCheck(e) : null
                  }
                  type="text"
                  placeholder="Ingrese el N° de doc."
                  {...formik.getFieldProps('documentId')}
                  className={
                    formik.touched.documentId && formik.errors.documentId ? 'with-error' : ''
                  }
                  onChange={(e) => {
                    e.target.value = e.target.value?.toUpperCase();
                    formik.getFieldProps('documentId').onChange(e);
                  }}
                  disabled={dataUrl.validate}
                />
                <PSError>{formik.touched.documentId && formik.errors.documentId}</PSError>
                <PSTextInput
                  placeholder="********"
                  type="password"
                  {...formik.getFieldProps('password')}
                  className={formik.touched.password && formik.errors.password ? 'with-error' : ''}
                />
                <PSError>{formik.touched.password && formik.errors.password}</PSError>
                <PSError>{errorLogin && errorLogin}</PSError>
              </div>
              <div className="login-footer">
                <Link to={Routes.FORGOT_PASSWORD} className="login-footer-forgot">
                  Olvidé mi contraseña
                </Link>
                <PSButton className="btn-submit" type="submit" loading={tryLogIn && needLoading()}>
                  Ingresar
                </PSButton>
                <div>
                  <Link to={Routes.SIGN_UP} className="login-footer-signup">
                    ¿No tienes cuenta? <b> Regístrate</b>
                  </Link>
                </div>
              </div>
            </form>
          </PSLoginContainer>
        </PSBox>
      </div>
    </PSLanding>
  );
}
