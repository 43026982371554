import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { pathServer, STORAGE_TOKEN_COGNITO, URL_UPDATE_DATA } from '../../util/Constant';
import { menuCode, services } from '../shared/constants/Strings';
import { showMenuCoupon } from '../../util/Util';

import { Routes } from '../../routes';
import { GAProvider } from './PSAnalytics';

import { cleanAuth } from '../redux/auth';
import PSAsideBar from './PSAsideBar';
import PSIcon from './PSIcon';

const StyledPSNavBar = styled.nav`
  .navbar-item {
    text-align: center;
    img {
      height: 48px;
      max-height: max-content;
    }
  }
  .header-icon-user {
    vertical-align: unset;
    align-items: self-start;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 18px;
    padding: 0;
    margin-right: 0;
    svg {
      width: 1.75rem;
      height: auto;
      .a {
        fill: #2b0d61 !important;
      }
    }
  }

  .header-selected {
    vertical-align: unset;
    align-items: self-start;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 18px;
    padding: 0;
    margin-right: 0;
    svg {
      width: 1.75rem;
      height: auto;
      .a {
        fill: #ed6e00 !important;
      }
    }
  }
  .subItem:hover {
    color: white !important;
    background: #ed6e00;
  }
  .navbar-start {
    .navbar-item {
      padding: 18px 0;
      border: 0.5px solid #dbdbdb;
      font-weight: 600;
      padding: 15px 0;
      font-family: 'BlissPro-ExtraBold', sans-serif !important;
      color: #808080;
      font-size: 15px;
    }
    .signAuth {
      background: #ed6e00;
      color: #fff;
      font-family: 'BlissPro-ExtraBold', sans-serif !important;
      &.disabled {
        pointer-events: none;
        background: #f3a058;
      }
    }
  }

  .has-dropdown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }

  .product-header {
    color: #2b255b !important;
  }
  .product {
    color: #2b255b !important;
    &:hover {
      background: #2b255b9c !important;
      color: white !important;
    }
  }

  .navbar-burger {
    height: initial;
    background-color: transparent;
  }

  .navbar-burger span {
    background-color: #ed6e00 !important;
  }

  .navbar-link {
    background: #2b255b;
    color: white;
    padding: 10px 0;
  }

  .navbar-dropdown {
    padding-top: 0;
  }

  .navbar-end {
    display: flex;
    justify-content: center;
    .button {
      color: white;
      background: #ed6e00 !important;
    }
  }
  .icon-arrow {
    display: inline-block;
    .d {
      fill: #808080;
    }

    svg {
      g {
        path {
          fill: #808080;
        }
      }
    }
  }

  .icon-arrow-product {
    display: inline-block;
    .d {
      fill: #2b255b;
    }

    svg {
      g {
        path {
          fill: #fff;
        }
      }
    }
  }

  .option-selected {
    background: #e4e4e4 !important;
    color: #808080 !important;
    .a {
      fill: white;
    }
  }

  .product-selected {
    background: #2b255b !important;
    color: white !important;
    .a {
      fill: white;
    }
  }

  .asidebar-mobile {
    position: absolute;
    .aside-contact-info {
      padding-bottom: 4rem;
      min-height: 100vh;
      .contact-info-icon {
        margin-top: 15vh;
      }
    }
  }
`;

const openNavBar = (e, closeAvatar) => {
  e.preventDefault();
  document.querySelector('.navbar-menu').classList.toggle('is-active');
  document.querySelector('.navbar-burger').classList.toggle('is-active');
  closeAvatar();
};

const closeNavBar = () => {
  document.querySelector('.navbar-menu').classList.remove('is-active');
  document.querySelector('.navbar-burger').classList.remove('is-active');
};
const PSNavBar = React.forwardRef(
  ({ openAsideBar, closeAsideBar, onOpenModalUpdateData, disabledSignOut, ...props }, ref) => {
    const { userInfo, isEditData } = useSelector((state) => state.auth);
    const [tramites, setTramites] = useState(false);
    const [consultas, setConsultas] = useState(false);
    const [productos, setProductos] = useState(false);
    const [showAvatar, setShowAvatar] = useState(false);
    const ga = React.useContext(GAProvider);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const servicesDetail = userInfo?.productsDetail || [];
    const issuesDateSoat = userInfo?.issuesDateSoat || [];
    const isBusiness = userInfo ? userInfo.isBusiness : false;
    const hasCoupons = process.env.REACT_APP_SHOW_COUPONS === 'true';
    const [soatVigency, setSoatVigency] = useState(false);
    const banners = useSelector((state) => state.auth).banners || {
      promotions: [],
    };

    const signAuth = () => {
      dispatch(cleanAuth());
      localStorage.removeItem(STORAGE_TOKEN_COGNITO);
      localStorage.clear();
      history.push(Routes.LOGIN);
    };

    const goToNewSletter = () => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Newsletter`,
        action: 'click',
        label: 'El usuario visitó la página principal de Newsletter',
        value: 1,
      });
      goToPage(Routes.HOME_NEWSLETTER);
    };

    const goToPage = (page, menuId = '', menuDescription = '', policies = []) => {
      const state = {
        menuId,
        menuDescription,
        policies,
      };

      if (location.pathname === URL_UPDATE_DATA && isEditData) {
        onOpenModalUpdateData(page, state);
      } else {
        history.push({ pathname: page, state });
      }
    };

    useEffect(() => {
      setSoatVigency(
        showMenuCoupon(servicesDetail, banners.promotions, issuesDateSoat, userInfo.showPromoFree)
      );
    }, [servicesDetail, banners.promotions, issuesDateSoat, userInfo.showPromoFree]);

    return (
      <StyledPSNavBar
        {...props}
        showAvatar={showAvatar}
        className="navbar"
        role="navigation"
        aria-label="main navigation"
        ref={ref}
      >
        <div className="navbar-brand ">
          <a className="navbar-item" href="#">
            <img
              src={`${pathServer.PATH_ICONS}logo_oficial.jpeg`}
              onClick={() => {
                history.push(Routes.HOME);
              }}
            />
          </a>
          <PSIcon
            type="user"
            className={`navbar-item ${showAvatar ? 'header-icon-user' : ' header-selected'}`}
            onClick={() => {
              setShowAvatar(!showAvatar);
              closeNavBar();
              if (!showAvatar) {
                openAsideBar();
              } else {
                closeAsideBar();
              }
            }}
          />

          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={(e) => openNavBar(e, () => setShowAvatar(false))}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            {process.env.REACT_APP_AVAILABLE_OPTION_TRAMITES === 'true' && (
              <a
                className={`navbar-item ${tramites ? ' option-selected' : ''}`}
                onClick={() => {
                  setTramites(!tramites);
                  setConsultas(false);
                }}
              >
                Trámites <PSIcon type={tramites ? 'arrowUp' : 'arrowDown'} className="icon-arrow" />
              </a>
            )}

            {tramites && !consultas && (
              <div>
                {/* <a className="navbar-item subItem">Ingreso de solicitudes</a> */}
                <a
                  className="navbar-item subItem"
                  onClick={() => goToPage(Routes.HOME_REGISTER_CLAIM)}
                >
                  Ingreso de reclamos
                </a>
              </div>
            )}
            <a
              className={`navbar-item ${consultas ? ' option-selected' : ''}`}
              onClick={() => {
                setConsultas(!consultas);
                setTramites(false);
              }}
            >
              Consultas <PSIcon type={consultas ? 'arrowUp' : 'arrowDown'} className="icon-arrow" />
            </a>
            {consultas && !tramites && (
              <div>
                <a
                  className="navbar-item subItem"
                  onClick={() => goToPage(Routes.HOME_STATE_REQUEST)}
                >
                  <span>Estado de solicitudes</span>
                </a>
                <a
                  className="navbar-item subItem"
                  onClick={() => goToPage(Routes.HOME_STATE_PROCEDURE)}
                >
                  <span>Estado de trámites</span>
                </a>
                <a
                  className="navbar-item subItem"
                  onClick={() => goToPage(Routes.HOME_STATE_CLAIM)}
                >
                  <span>Estado de reclamos</span>
                </a>
                <a
                  className="navbar-item subItem"
                  onClick={() => goToPage(Routes.HOME_FREQUENT_QUESTION)}
                >
                  Preguntas Frecuentes
                </a>
              </div>
            )}
            {!isBusiness && (
              <a className="navbar-item" onClick={() => goToPage(Routes.HOME_REWARDS)}>
                Club Protecta Security{' '}
              </a>
            )}

            {process.env.REACT_APP_PERMISSION_NEWSLATTERS === 'true' && (
              <a className="navbar-item" onClick={() => goToNewSletter()}>
                Newsletter
              </a>
            )}

            {soatVigency && hasCoupons && (
              <a className="navbar-item" onClick={() => goToPage(Routes.HOME_COUPONS)}>
                Promo gratis
              </a>
            )}

            <div className="navbar-item has-dropdown is-hoverable">
              <a
                className={`navbar-item product-header ${productos ? ' product-selected' : ''}`}
                onClick={() => setProductos(!productos)}
              >
                Productos contratados
                <PSIcon type={productos ? 'arrowUp' : 'arrowDown'} className="icon-arrow-product" />
              </a>
              {productos && (
                <div className="navbar-dropdown">
                  {servicesDetail.map(({ product, productDescription, policies }) => {
                    const useExponent = product === menuCode.VIDA_DEVOLUCION;
                    return (
                      <a
                        key={product}
                        className="navbar-item product"
                        onClick={() =>
                          goToPage(services[product].page, product, productDescription, policies)
                        }
                      >
                        {productDescription}
                        {useExponent && (
                          <span className="exponent" style={{ fontSize: '.6rem' }}>
                            +
                          </span>
                        )}
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
            <a
              className={`navbar-item signAuth ${disabledSignOut ? 'disabled' : ''} `}
              onClick={signAuth}
            >
              Cerrar sesión
            </a>
          </div>
        </div>
        {showAvatar && (
          <PSAsideBar
            showProducts={false}
            onCloseBar={() => {
              setShowAvatar(false);
              closeAsideBar();
            }}
            className="asidebar-mobile"
          />
        )}
      </StyledPSNavBar>
    );
  }
);

PSNavBar.defaultProps = {
  openAsideBar: () => {},
  closeAsideBar: () => {},
  onOpenModalUpdateData: () => {},
};

PSNavBar.propTypes = {
  openAsideBar: PropTypes.func,
  closeAsideBar: PropTypes.func,
  onOpenModalUpdateData: PropTypes.func,
};

export default PSNavBar;
