import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createErrorPage } from '../../redux/auth';

import GetBenefits from '../../shared/services/GetBenefits';
import { Reward } from '../../shared/model/Reward';
import { defaultSetError } from '../../redux/auth/actions/set-error-page';

import PSHeaderIntranet from '../../components/PSHeaderIntranet';
import PSLabel from '../../components/PSLabel';
import PSContainerPrize from '../../components/PSContainerPrize';
import PSLoading from '../../components/PSLoading';
import PSCarousel from '../../components/PSCarousel';
import { GAProvider } from '../../components/PSAnalytics';
import PSOptionRegisterPolicy from '../../components/PSOptionRegisterPolicy';

import './index.scss';

const img = 'Banner_footer_PS';
const imgMobile = 'Home_footer_PS';

export default function HomePrize(props) {
  const [loading, setLoading] = useState(true);
  const [rewards, setListRewards] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const isBusiness = userInfo ? userInfo.isBusiness : false;
  const dispatch = useDispatch();

  const ga = React.useContext(GAProvider);
  const responseBenefits = async () => {
    try {
      const response = await GetBenefits.responseGetBenefits();
      setLoading(false);
      const reward = response.map((r) => new Reward(r));
      setListRewards([...reward]);
    } catch (error) {
      setLoading(false);
      dispatch(createErrorPage({ ...defaultSetError, onAction: () => {} }));
    }
  };

  useEffect(() => {
    responseBenefits();
  }, []);

  useEffect(() => {
    const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
    ga.pageview(window.location.pathname + window.location.search);
    ga.event({
      category: `${messageTypeUser}Club Protecta Security`,
      action: 'click',
      label: 'El usuario visitó la página de Club Protecta Security',
      value: 1,
    });
  }, [ga]);

  return (
    <div className="home-prize-container">
      <div className="header">
        <PSHeaderIntranet
          hasEffectGray={false}
          img={img}
          imgMobile={imgMobile}
          description="Club Protecta Security"
          icon="protectionPrize"
          {...props}
          className="prize-banner"
        />
      </div>
      {loading ? (
        <div className="loading-pages-intranet">
          <PSLoading />
        </div>
      ) : rewards.length === 0 ? (
        <div className="body-optionRegister">
          <PSOptionRegisterPolicy
            url="http://www.clubprotectasecurity.pe/"
            message={
              <p>
                Estás muy cerca de disfrutar los beneficios y sorteos mensuales de{' '}
                <strong>Club Protecta Security.</strong>
              </p>
            }
            textButton="¡Inscríbete aquí"
            withLink={false}
          />
        </div>
      ) : (
        <>
          <div className="section-titulo mt-2">
            <PSLabel className="titulo">
              Para conocer más información y beneficios ingrese a
              <a
                href="http://www.clubprotectasecurity.pe/"
                target="_blank"
                rel="noreferrer"
                className="titulo"
              >
                clubprotectasecurity.pe
              </a>
            </PSLabel>
          </div>
          <div className="container-list">
            <div className="columns is-multiline display-none-mobile">
              {rewards.slice(0, 6).map((reward) => (
                <div className="column is-one-third " key={reward.subtitle}>
                  <PSContainerPrize
                    img={reward.img}
                    title={reward.title}
                    subtitle={reward.subtitle}
                    onClick={() => {
                      window.open(`http://www.clubprotectasecurity.pe/`);
                      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
                      ga.pageview(window.location.pathname + window.location.search);
                      ga.event({
                        category: `${messageTypeUser}Club Protecta Security`,
                        action: 'click',
                        label: `El usuario entro al link de redireccionamiento de ${reward.title} de Club Protecta Security`,
                        value: 1,
                      });
                    }}
                  />
                </div>
              ))}
            </div>

            <div className="columns display-only-mobile">
              <PSCarousel typeIcon="secondary">
                {rewards.slice(0, 3).map((reward) => (
                  <div className="column " key={reward.img}>
                    <PSContainerPrize
                      img={reward.img}
                      title={reward.title}
                      subtitle={reward.subtitle}
                      onClick={() => window.open(`http://www.clubprotectasecurity.pe/`)}
                    />
                  </div>
                ))}
              </PSCarousel>
            </div>
            <div className="columns display-only-mobile">
              <PSCarousel typeIcon="secondary">
                {rewards.slice(3, 6).map((reward) => (
                  <div className="column " key={reward.img}>
                    <PSContainerPrize
                      img={reward.img}
                      title={reward.title}
                      subtitle={reward.subtitle}
                    />
                  </div>
                ))}
              </PSCarousel>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
