import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Kushki } from '@kushki/js';
import { useFormik } from 'formik';
import isEmpty from 'loadsh/isEmpty';

import { loadInitLibray } from '../../../../util/Niubiz';
import { createErrorPage, setLoginUser } from '../../../redux/auth';
import { GAProvider } from '../../PSAnalytics';
import { isDevelopment } from '../../../../util/Util';
import { customValidations, validationsExpiryDate } from './constants';

import PSDynamicTable from '../../PSDynamicTable';
import DevolutionLyfe from '../../../shared/services/DevolutionLyfe';
import PSLoading from '../../PSLoading';
import OTPValidation from '../../../pages/OTPValidation';
import { PSModalKushki } from '../../organisms';
import { Card } from '../../../shared/model/Card';
import PSModalPayment from '../../organisms/PSModalPayment/PSModalPayment';

import './index.scss';

const headerTable = [
  { elementProp: 'nrCard', headerColumn: 'Número de tarjeta' },
  { elementProp: 'typeCard', headerColumn: 'Tipo de tarjeta' },
];
const SECURE3D = '3dsecure';
const PSUpdateCard = React.forwardRef(
  ({ poliza, className, onCloseOption, menuDescription }, ref) => {
    const ga = React.useContext(GAProvider);
    const { userInfo } = useSelector((state) => state.auth);
    const { kushkiAtributes: { key, policyVigent = null, cardUpdated } = {} } = userInfo;
    const [showModalValidation, setShowModalValidation] = useState(false);
    const [showModalOtp, setShowModalOtp] = useState(false);
    const [showOtpError, setShowOtpError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingKushki, setLoadKushki] = useState(false);
    const [cardSelected, setCardSelected] = useState(null);
    const [cards, setCards] = useState([]);
    const [showEditColumn, setShowEditColumn] = useState(true);
    const [urlEndpoint, setUrlEndpont] = useState(null);
    const user = userInfo ? userInfo.attributes : {};
    const isBusiness = userInfo ? userInfo.isBusiness : false;
    const { rol } = poliza;
    const dispatch = useDispatch();
    const [showModalKushki, setShowModalKushki] = useState(false);
    const [tokenKushki, setTokenKushki] = useState('');
    const [errorKushki, setErrorKushki] = useState(null);
    const [useKushki, setKushki] = useState(true);
    const [errorOtp , setErrorOtp] = useState('');
    const paramsKushki = {
      id: poliza.id,
      idTokenizacion: cardSelected?.idTokenizacion,
      tokenInicial: cardSelected?.tokenInicial,
      token: tokenKushki,
    };

    const onChangeForm = () => {
      setErrorKushki(false);
    };

    const formik = useFormik({
      initialValues: {
        cardNumber: '',
        cardExpiry: '',
        cardCvv: '',
      },
      validationSchema: customValidations,
      onSubmit(form) {
        const error = validationsExpiryDate(form.cardExpiry);
        if (isEmpty(error)) {
          onPayCard(form);
        } else {
          console.log('error', error);
        }
      },
    });

    const onPayCard = async ({ cardNumber, cardExpiry, cardCvv }) => {
      const isTestRoute = process.env.REACT_APP_USE_ROUTE_TEST === 'true';
      try {
        setLoadKushki(true);
        let kushki = new Kushki({
          merchantId: cardSelected?.llavePublica,
          inTestEnvironment: isTestRoute,
          regional: false,
        });
        const expiry = cardExpiry?.split('/');
        const paramsKushki = {
          currency: cardSelected?.typeMoneda,
          card: {
            name: user?.legalName,
            number: cardNumber,
            cvc: cardCvv,
            expiryMonth: expiry[0],
            expiryYear: expiry[1],
          },
        };

        kushki.requestSubscriptionToken(paramsKushki, async (response) => {
          if (!response.code) {
            if (response?.secureService === SECURE3D) {
              kushki.requestValidate3DS(response, (res) => {
                if (!res.code) {
                  onUpdateKushki(response?.token);
                } else {
                  console.log('Error3DS: ', res);
                  setLoadKushki(false);
                }
              });
            } else {
              onUpdateKushki(response?.token);
            }
          } else {
            console.log('Error subscription: ', response);
            setErrorKushki(response?.message);
            setLoadKushki(false);
          }
        });
      } catch {
        setLoadKushki(false);
      }
    };

    const onUpdateKushki = async (tokenKushki) => {
      if (poliza?.nroPolicy === policyVigent) {
        const headers = { 'validation-type': '30', 'validation-data': key };
        const body = {
          ...paramsKushki,
          token: tokenKushki,
        };
        const { data, error } = await DevolutionLyfe.postUpdateCardKushki(body, headers);
        if (data?.success && isEmpty(error)) {
          setShowModalValidation(false);
          setShowModalOtp(true);
          //updated userInfo
          const userUpdated = userInfo;
          userUpdated.kushkiAtributes.cardUpdated = true;
          dispatch(setLoginUser({ ...userUpdated }));
        } else {
          dispatch(
            createErrorPage({
              state: true,
              message: 'Error al actualizar la tarjeta',
              onAction: () => onCloseOption(),
              icon: 'face',
              title: 'Error',
            })
          );
        }
        setShowModalKushki(false);
        setLoadKushki(false);
      } else {
        setShowModalKushki(false);
        setLoadKushki(false);
        setTokenKushki(tokenKushki);
        setUrlEndpont(process.env.REACT_APP_UPDATE_CARD_KUSHKI);
        setShowModalValidation(true);
        onOTPValidation('init');
      }
    };
    const handleCardSelect = (card) => {
      setCardSelected(card);
      useKushki ? onEditKuhski(card) : onEditNiubiz(card);
    };

    const onEditKuhski = async (card) => {
      formik.resetForm();
      setShowModalKushki(true);
      setErrorKushki(null);
    };

    const onEditNiubiz = async (card) => {
      try {
        setLoading(true);
        const response = await DevolutionLyfe.getDataModalNiubiz(
          poliza.nroPolicy,
          card.codComercio
        );
        if (response.needValidation) {
          const { url } = response;
          setUrlEndpont(url);
          setShowModalValidation(true);
          onOTPValidation('init');
        }
        setLoading(false);
      } catch (e) {
        dispatch(
          createErrorPage({
            onAction: () => {},
            state: 'true',
            icon: 'broken',
            title: 'Error!',
            message: 'Intente más tarde.',
          })
        );
        setLoading(false);
      }
    };

    const loadResponseOTP = (status, response) => {
      if (status) {
        const { data } = response;
        if (data.length > 0) {
          setShowModalValidation(false);
          loadInitLibray(data[0], poliza.id, cardSelected, user); //niubiz
        }
      } else {
        dispatch(
          createErrorPage({
            onAction: () => {},
            state: 'true',
            icon: 'broken',
            title: 'Error!',
            message: 'Intente más tarde.',
          })
        );
      }
    };

    const onSuccessEditKushki = (status, response) => {
      setShowModalValidation(false);
      setShowModalOtp(true);
    };

    const closeModalSucces = () => {
      setShowModalOtp(false);
      loadData();
    };

    const errorEditKushki = (status, response) => {
      setShowModalValidation(false);
      setShowOtpError(true);
    };

    const loadData = async () => {
      try {
        setLoading(true);
        let cardResponse = await DevolutionLyfe.getCurrentCard(poliza?.id);
        if (cardResponse) {
          const card = new Card(cardResponse);
          setShowEditColumn(card?.existPaymentGateway);
          setKushki(card?.useKushki);
          setCards([card]);
          onOTPValidation('visit');
        } else {
          dispatch(
            createErrorPage({
              state: true,
              message: 'No se encontró una tarjeta asociada a la póliza',
              onAction: () => onCloseOption(),
              icon: 'face',
              title: 'Error',
            })
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        dispatch(
          createErrorPage({
            state: true,
            message: 'Error al consultar listado de tarjetas',
            onAction: () => onCloseOption(),
            icon: 'face',
            title: 'Error',
          })
        );
      }
    };

    const onOTPValidation = (typeOTP = 'send', typeSend) => {
      const messageTypeUser = isBusiness ? 'EMPRESA-' : 'PERSONA-';
      const labelTypeSendGA = typeSend === 'phoneNumber' ? 'celular' : 'email';
      let labelGA = '';
      switch (typeOTP) {
        case 'send':
          labelGA = `El usuario solicitó el envío de la clave OTP por ${labelTypeSendGA}`;
          break;
        case 'success':
          labelGA = `El usuario validó la clave OTP correctamente enviada por ${labelTypeSendGA}`;
          break;
        case 'error':
          labelGA = `El usuario introdujo una clave OTP errónea enviada por ${labelTypeSendGA}`;
          break;
        case 'visit':
          labelGA = `El usuario visualizó el listado de tarjetas de ${menuDescription}`;
          break;
        case 'init':
          labelGA = `El usuario inició el flujo de Modificación de Tarjetas para cobro recurrente`;
          break;
        default:
          labelGA = `El usuario visualizó el listado de tarjetas de ${menuDescription}`;
      }

      ga.pageview(window.location.pathname + window.location.search);
      ga.event({
        category: `${messageTypeUser}Producto ${menuDescription}-Modificación de Tarjetas para cobro recurrente`,
        action: 'click',
        label: labelGA,
        value: 1,
      });
    };

    useEffect(() => {
      if (rol === 'Asegurado') {
        alert('No esta habilitado para esta opción');
        onCloseOption();
      } else {
        loadData();
      }
    }, [poliza]);

    useEffect(() => {
      if (poliza.nroPolicy === policyVigent && cards.length > 0 && !cardUpdated) {
        handleCardSelect(cards[0]);
      }
    }, [cards, cardUpdated]);

    return (
      <div className={`container-updateCard ${className}`} ref={ref}>
        {loading ? (
          <div className="loading">
            <PSLoading />
          </div>
        ) : (
          <div>
            {cards.length > 0 && (
              <PSDynamicTable
                headers={headerTable}
                items={cards}
                hasEdit={showEditColumn}
                onEdit={(card) => {
                  handleCardSelect(card);
                }}
              />
            )}
            {showModalValidation && (
              <Modal
                animationDuration={0}
                open={showModalValidation}
                onClose={() => {}}
                showCloseIcon={false}
                onOverlayClick={() => {}}
                closeOnOverlayClick={false}
              >
                <OTPValidation
                  dataSave={useKushki ? paramsKushki : { merchantCode: cardSelected.codComercio }}
                  headerParams={useKushki ? null : { policy: poliza.nroPolicy }}
                  url={urlEndpoint}
                  onValid={(status, response , error) => {
                    if (status) {
                      onOTPValidation('success');
                      if (useKushki) {
                        onSuccessEditKushki(status, response);
                      } else {
                        loadResponseOTP(status, response);
                      }
                    } else {
                        errorEditKushki();
                        setErrorOtp(error);
                   }
                  }}
                  onSendValidation={(typeSend) => {
                    onOTPValidation('send', typeSend);
                  }}
                  onSendErrorCode={(typeSend, data = {}) => {
                    const { message, success } = data;
                    if (!success && message) {
                      showOtpError(true);
                    }
                    onOTPValidation('error', typeSend);
                  }}
                  onCloseModal={() => {
                    setShowModalValidation(false);
                  }}
                />
              </Modal>
            )}
            {showModalKushki && (
              <PSModalKushki
                loading={loadingKushki}
                openModal={showModalKushki}
                center={false}
                onClose={() => setShowModalKushki(false)}
                formik={formik}
                llavePublica={cardSelected?.llavePublica}
                errorApi={errorKushki}
                onChangeForm={onChangeForm}
              />
            )}
            {showModalOtp && (
              <PSModalPayment
                openModal={showModalOtp}
                closeModal={closeModalSucces}
                onOk={closeModalSucces}
                okText="Continuar"
                title="Tarjeta modificada con éxito"
                iconSuccess
                description={
                  <span>
                    ¡Los detalle de tu tarjeta de pago han sido <br />
                    actualizados exitosamente!
                  </span>
                }
                icon="success"
              />
            )}
            {showOtpError && (
              <PSModalPayment
                openModal={showOtpError}
                closeModal={() => setShowOtpError(false)}
                onOk={() => setShowOtpError(false)}
                okText="Continuar"
                iconError
                title="Error al modificar tarjeta"
                description={
                  <span>
                    ¡Lo sentimos, ha ocurrido un error al <br />
                    modificar tu tarjeta de pago!
                    {errorOtp && (
                      <>
                        <br /><br />
                        <span>Descripción del error:<br />{errorOtp}</span>
                      </>
                    )}
                  </span>
                }
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

PSUpdateCard.displayName = 'PSUpdateCard';

PSUpdateCard.defaultProps = {
  className: '',
  poliza: null,
  onCloseOption: null,
};

PSUpdateCard.propTypes = {
  className: PropTypes.string,
  poliza: PropTypes.object,
  onCloseOption: PropTypes.func,
};

export default PSUpdateCard;
